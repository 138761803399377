/* eslint-disable */
import Api from "./Api";
export default {
  async saveContact(payload) {
    const response = await Api().post(`api/public/common`, payload);
    return response;
  },
  async saveCustomer(payload) {
    const response = await Api().post(`api/public/common/customer`, payload);
    return response;
  },
  async getAllStatList(payload) {
    const response = await Api().get(`api/public/common/dash-count/?search=${payload}`);
    return response;
  },
  async getAllData(payload) {
    const response = await Api().get(`api/public/common/${payload}`);
    return response;
  },
  async uploadDoc(payload) {
    const response = await Api().post(`api/public/common/upload`, payload);
    return response;
  },

};
