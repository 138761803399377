import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { subApiSchema } from "../../../../schemas";
import Table from "../../../../components/tables/table";
import { subApi_columns } from "../../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toast";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../../helper/commonHelper";
import { FaSpinner } from "react-icons/fa";
import ApiSubServices from "../../../../ApiServices/ApiSubServices"
import ApiServices from "../../../../ApiServices/ApiServices"
import Select from "react-select";
import makeAnimated from 'react-select/animated';

function SubApiMasterList() {
	const pages = [{ title: "Sub Api List", href: "/sub-api", module_id: 3 }];
	const [isLoading, setIsLoading] = useState(true);
	const [modalOpenFlage, setmodalOpenFlage] = useState(false);
	const [subApiList, setSubApiList] = useState([]);
	const [apiList, setApiList] = useState([]);
	const [sapi_id, setSubApiid] = useState(0);
	const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		sapi_id: null,
	});
	const navigate = useNavigate();
	const [disableSubmitButton, setDisableSubmitButton] = useState(false);
	const [selectedApiId, setSelectedApiId] = useState({ value: '', label: '' });
	const animatedComponents = makeAnimated();

	const [allowedActions, setAllowedActions] = useState([]);
	useEffect(() => {
		const permissions = JSON.parse(localStorage.getItem("user_permissions"));
		if (permissions && window.location.pathname) {
			const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
			const actions = getAllowedActions(permissions, window.location.pathname);
			setAllowedActions(actions);
		}
	}, []);

	useEffect(() => {
		document.title = "Fire  • Sub Api";
		getAllSubApis(3);
		getAllApis(1);
	}, []);
	const getAllApis = async (payload) => {
		setIsLoading(true);
		try {
			const res = await ApiServices.getAllApi(payload)
			if (res.status) {
				setApiList(res.data.data);
			}
			setIsLoading(false);
		} catch (e) {
			console.log(e, "error in getAllApis")
			toast.error("Fatal Error")
		}
	}
	const getAllSubApis = async (payload) => {
		setIsLoading(true);
		try {
			const res = await ApiSubServices.getAllSubApi(payload)
			if (res.status) {
				setSubApiList(res.data.data);
			}
			setIsLoading(false);
		} catch (e) {
			console.log(e, "error in getAllSubApis")
			toast.error("Fatal Error")
		}
	}

	const initialValues = {
		sapi_id: "",
		sapi_name: "",
		sapi_endpointe: "",
		sapi_code: "",
		sapi_descp: "",
		sapi_uptime: "",
		fk_api_id: "",
		sapi_is_active: "",
	};

	const [formSubApi, setFormSubApi] = useState(initialValues);

	const handleDrawer = (type, id, obj) => {
		setSelectedApiId({ value: '', label: '' });
		if ((type === "edit", id)) {
			setSubApiid(id);
			const initialValues = {
				sapi_id: obj.sapi_id,
				sapi_name: obj.sapi_name,
				sapi_endpointe: obj.sapi_endpointe,
				sapi_code: obj.sapi_code,
				sapi_descp: obj.sapi_descp,
				sapi_uptime: obj.sapi_uptime,
				fk_api_id: obj.fk_api_id,
				sapi_is_active: obj.sapi_is_active,
			};
			if (Array.isArray(apiList) && apiList.length > 0) {
				let domain = apiList.filter(v => v.api_id == initialValues.fk_api_id)
				if (domain.length > 0) {
					setSelectedApiId({ value: domain[0].api_id, label: domain[0].api_name })
				}
			}
			setFormSubApi(initialValues);
		} else {
			setSubApiid("");
			setFormSubApi(initialValues);
		}
		if (modalOpenFlage === false) {
			setmodalOpenFlage(true);
		}
	};

	const onDeleteOpen = (sapi_id) => {
		setConfirmationModal({ sapi_id, status: true });
	};

	const onDeleteSubApi = async (sapi_id) => {
		setIsLoading(true);
		let { data, status } = await ApiSubServices.deleteSubApi({ sapi_id })
		if (status === 200) {
			setIsLoading(false);
			if (data.status) {
				toast.success(data.message);
				getAllSubApis(3);
			}
		} else {
			toast.error(data.message);
		}
		setIsLoading(false);
		setConfirmationModal({ sapi_id: null, status: false });
	};


	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			enableReinitialize: true,
			initialValues: formSubApi,
			validationSchema: subApiSchema,
			onSubmit: async (values, action) => {
				console.log("Submit Clicked", values);
				let body = {
					sapi_id: sapi_id,
					sapi_is_active: true,
					sapi_name: values.sapi_name,
					sapi_endpointe: values.sapi_endpointe,
					sapi_code: values.sapi_code,
					sapi_descp: values.sapi_descp,
					sapi_uptime: values.sapi_uptime,
					fk_api_id: values.fk_api_id,
				};
				if (sapi_id === undefined || sapi_id === null || sapi_id === "") {
					setIsLoading(true);
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					await upsertSubApi(body);
				} else {
					setIsLoading(true);
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					await upsertSubApi(body);
				}
				action.resetForm();
				getAllSubApis(3);
				if (modalOpenFlage === true) {
					getAllSubApis(3);
					setmodalOpenFlage(false);
					setDisableSubmitButton(false);
				}
				navigate("/sub-api");
			},
		});
	const upsertSubApi = async (payload) => {
		let { data, status } = await ApiSubServices.SaveSubApi(payload)
		if (status === 200) {
			if (data.status) {
				toast.success(data.message);
			} else {
				toast.error(data.message);
			}
		} else {
			toast.error(data.message);
		}
		setIsLoading(false);
	}
	return (
		<div className="px-4 sm:px-4 lg:px-4">
			<ConfirmationModal
				title={'Are you sure you want to delete this Api Key?'}
				open={confirmationModal.status}
				setOpen={setConfirmationModal}
				onDelete={() => onDeleteSubApi(confirmationModal.sapi_id)}
			/>
			<Breadcrumb pages={pages} />
			<div className="">
				<h1 className="text-xl font-semibold text-gray-900">Sub Api List</h1>
			</div>
			<div className="mt-4 flex">
				{allowedActions.filter((data) => data.permission_id == 76)?.length > 0 ? (
					<Link
						onClick={() => handleDrawer("add", "", {})}
						type="button"
						className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
					>
						Add Sub Api
					</Link>
				) : null}
			</div>

			{isLoading ? (
				<FallingLinesLoader />
			) : (
				<Table
					columns={subApi_columns({ onDeleteOpen, handleDrawer, allowedActions })}
					data={subApiList}
					is_toggle={false}
				/>
			)}

			<div>
				<Transition.Root show={modalOpenFlage} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						onClose={setmodalOpenFlage}
					>
						<div className="fixed inset-0" />
						<div className="fixed inset-0 overflow-hidden">
							<div className="absolute inset-0 overflow-hidden">
								<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
									<Transition.Child
										as={Fragment}
										enter="transform transition ease-in-out duration-500 sm:duration-700"
										enterFrom="translate-x-full"
										enterTo="translate-x-0"
										leave="transform transition ease-in-out duration-500 sm:duration-700"
										leaveFrom="translate-x-0"
										leaveTo="translate-x-full"
									>
										<Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
											<form
												onSubmit={(e) => {
													e.preventDefault();
													console.log(e);
													handleSubmit(e);
												}}
												className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
											>
												<div className="h-0 flex-1 overflow-y-auto">
													<div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9%    to-gray-900 to-80%  py-6 px-4 sm:px-6">
														<div className="flex items-center justify-between">
															<Dialog.Title className="text-lg font-medium text-white">
																{sapi_id > 0 ? "Update" : "Add"} SubApi
															</Dialog.Title>
															<div className="ml-3 flex h-7 items-center">
																<button
																	type="button"
																	className="rounded-md bg-gradient-to-b from-[#11ffdb] to-[#0172fd] text-white hover:text-white"
																	onClick={() => setmodalOpenFlage(false)}
																>
																	<span className="sr-only">Close panel</span>
																	<XMarkIcon
																		className="h-6 w-6"
																		aria-hidden="true"
																	/>
																</button>
															</div>
														</div>
													</div>
													<div className="flex flex-1 flex-col justify-between">
														<div className="divide-y divide-gray-200 p-4 sm:p-6">
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="sapi_name"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Sub-Api Name
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.sapi_name}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Sub-Api Name"
																		name="sapi_name"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.sapi_name &&
																		touched.sapi_name ? (
																		<p className="text-red-600 text-sm">
																			{errors.sapi_name}
																		</p>
																	) : null}
																</div>
															</div>

															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="sapi_endpointe"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Endpointe
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.sapi_endpointe}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Endpointe"
																		name="sapi_endpointe"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.sapi_endpointe &&
																		touched.sapi_endpointe ? (
																		<p className="text-red-600 text-sm">
																			{errors.sapi_endpointe}
																		</p>
																	) : null}
																</div>
															</div>
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="sapi_code"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Code
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.sapi_code}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Sub Api Code"
																		name="sapi_code"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.sapi_code &&
																		touched.sapi_code ? (
																		<p className="text-red-600 text-sm">
																			{errors.sapi_code}
																		</p>
																	) : null}
																</div>
															</div>
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="fk_api_id"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Select Api Name
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	{apiList && apiList.length > 0 ? (
																		<div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
																			<Select
																				name="api_name"
																				id="api_id"
																				menuPlacement="top"
																				placeholder={
																					<span>
																						Select Domain
																						<span className="text-red-600">*</span>
																					</span>
																				}
																				value={selectedApiId}
																				onChange={(e) => {
																					console.log(e);
																					values.fk_api_id = e.value;
																					setSelectedApiId(e);
																				}}
																				components={animatedComponents}
																				options={
																					apiList
																						? apiList.map((v) => ({
																							value: v.api_id,
																							label: v.api_name,
																						}))
																						: []
																				}
																				classNamePrefix="select"
																				className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																			/>

																			{errors.fk_api_id && touched.fk_api_id ? (
																				<div className="text-sm text-red-600">
																					{errors.fk_api_id.replace("fk_api_id", "Domain Name")}
																				</div>
																			) : null}
																		</div>
																	) : null}

																</div>
															</div>
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="sapi_descp"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Sub-Api Desc
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<textarea
																		value={values.sapi_descp}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Sub-Api Desc"
																		name="sapi_descp"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.sapi_descp &&
																		touched.sapi_descp ? (
																		<p className="text-red-600 text-sm">
																			{errors.sapi_descp}
																		</p>
																	) : null}
																</div>
															</div>
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="sapi_uptime"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Uptime
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.sapi_uptime}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter UpTime"
																		name="sapi_uptime"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.sapi_uptime &&
																		touched.sapi_uptime ? (
																		<p className="text-red-600 text-sm">
																			{errors.sapi_uptime}
																		</p>
																	) : null}
																</div>
															</div>
															



														</div>
													</div>
												</div>
												<div className="flex flex-shrink-0 justify-end px-4 py-4">
													<button

														type="button"
														className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
														onClick={() => {
															setmodalOpenFlage(false);
														}}
													>
														Cancel
													</button>
													<button
														disabled={disableSubmitButton}
														type="submit"
														className="ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b from-[#11ffdb] to-[#0172fd] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
													>
														{sapi_id > 0 ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Add")}

													</button>
												</div>
											</form>
										</Dialog.Panel>
									</Transition.Child>
								</div>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			</div>
		</div>
	);
}
export default SubApiMasterList;
