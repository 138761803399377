/* eslint-disable */
import Api from "./Api";
export default {

  async SaveDomain(payload) {
    const response = await Api().post(`api/dashboard/domain`,payload);
    return response;
  },

  async getAllDomain(payload) {
    const response = await Api().get(`api/dashboard/domain?search=${payload}`);
    return response;
  },
  
  async getSingleDomain(payload) {
    const response = await Api().get(`api/dashboard/domain/${payload}`);
    return response;
  },

  async setDomainStatus(payload) {
    const response = await Api().post(`api/dashboard/domain/status`, payload);
    return response;
  },

  async deleteDomain(payload) {
    const response = await Api().post(`api/dashboard/domain/remove`, payload);
    return response;
  },

};
