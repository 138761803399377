/* eslint-disable */
import Api from "./Api";
export default {

  async SaveBlog(payload) {
    const response = await Api().post(`api/dashboard/blog`,payload);
    return response;
  },

  async getAllBlog(payload) {
    const response = await Api().get(`api/dashboard/blog?search=${payload}`);
    return response;
  },
  
  async getSingleBlog(payload) {
    const response = await Api().get(`api/dashboard/blog/${payload}`);
    return response;
  },

  async setBlogStatus(payload) {
    const response = await Api().post(`api/dashboard/blog/status`, payload);
    return response;
  },

  async deleteBlog(payload) {
    const response = await Api().post(`api/dashboard/blog/remove`, payload);
    return response;
  },

};
