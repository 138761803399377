
import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { BASEURL } from '../../../../helper/constant'

const SettingDescription = ({ values, handleChange, handleBlur, errors, touched }) => {
  const editorRef = useRef(null);
  const changeJsonVal = (value) => {
   console.log("PS=>",value)
  }
  return (
    <div className="mb-4 mt-5">
      <label htmlFor="setting_desp" className="block text-sm font-medium leading-6 text-gray-900">
        Description
      </label>
      <div>
        <Editor
          tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
          onEditorChange={e => { changeJsonVal(e) }}
          initialValue={values?.setting_desp ? values?.setting_desp : null }

        onInit={(evt, editor) => {
          console.log("editor", editor)
          return editorRef.current = editor
        }}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
            'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
            'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
          ],
          toolbar: 'undo redo | blocks | ' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
        }}
        />
        <textarea
          rows="5"
          value={values.setting_desp}
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          placeholder="Enter Description"
          name="setting_desp"
          autoComplete="off"
          className="block w-full rounded-md border-[1px] py-2 px-3 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:text-sm"
        />
        {errors.setting_desp && touched.setting_desp ? (
          <p className="text-red-600 text-sm mt-1">
            {errors.setting_desp}
          </p>
        ) : null}
      </div>
    </div>

  );
};

export default SettingDescription;