import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { genderSchema } from "../../../schemas";
import Table from "../../../components/tables/table";
import { gender_columns } from "../../../components/tables/tableheader";

import { Dialog, Transition } from "@headlessui/react";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Breadcrumb from "../../../components/Breadcrumb";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Api from "../../../ApiServices/Api";

const API = process.env.REACT_APP_API_URL;

function GenderList() {
	const pages = [{ title: "Gender List", href: "/gender", module_id: 22 }];
	const [genderList, setGenderList] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [modalOpenFlage, setmodalOpenFlage] = useState(false);
	const [gender_id, setGenderid] = useState("");
	const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		gender_id: null,
	});
	const navigate = useNavigate();

	useEffect(() => {
		document.title = "Fire  • Genders";
		getAllGenders()
	}, []);

	const getAllGenders = async () => {
		try {
			setIsLoading(true)
            const {data} = await Api().get(`/api/dashboard/masters/gender`);
			if(data.status ){
				setGenderList(data.data);
			}
			setIsLoading(false)
        } catch (error) {
            console.log(error);
			setIsLoading(false)
        }
    };

	const addGender = async () => {
		try {
			setIsLoading(true)
			// console.log("Add Gender")
			let body = {
				gender_name_en: values.gender_name_en,
				gender_name_ar: values.gender_name_ar,
            };
			const res = await Api().post(`/api/dashboard/masters/gender`,  gender_id !== undefined || gender_id !== null || gender_id !== "" ? {...body, gender_id} : body);
			if(res.status ){
				getAllGenders()
			}
			// console.log("Add Gender Response", res)
			setIsLoading(false)
        } catch (error) {
            console.log(error);
			setIsLoading(false)
        }
    };
	
	const initialValues = {
		gender_id: "",
		gender_name_en: "",
		gender_name_ar: "",
	};

	const [formGender, setFormGender] = useState(initialValues);

	const handleDrawer = (type, id, obj) => {
		if ((type === "edit", id)) {
			setGenderid(id);
			const initialValues = {
				gender_id: obj.gender_id,
				gender_name_en: obj.gender_name_en,
				gender_name_ar: obj.gender_name_ar,
			};
			setFormGender(initialValues);
		} else {
			setGenderid("");
			setFormGender(initialValues);
		}
		if (modalOpenFlage === false) {
			setmodalOpenFlage(true);
		}
	};
	const onDeleteOpen = (gender_id) => {
		setConfirmationModal({ gender_id, status: true });
	};

	const onDeleteGender = (gender_id) => {
		setConfirmationModal({ gender_id: null, status: false });
	};
	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			enableReinitialize: true,
			initialValues: formGender,
			validationSchema: genderSchema,
			onSubmit: (values, action) => {
				// console.log("Submit Clicked", values);
                addGender()
				// console.log("Update Gender")
				action.resetForm();
				if (modalOpenFlage === true) {
					getAllGenders()
					setmodalOpenFlage(false);
				}
				navigate("/gender");
			},
		});
	return (
		<div className="px-4 sm:px-4 lg:px-4">
			<ConfirmationModal
				open={confirmationModal.status}
				setOpen={setConfirmationModal}
				onDelete={() => onDeleteGender(confirmationModal.gender_id)}
			/>
			<Breadcrumb pages={pages} />
			<div className="">
				<h1 className="text-xl font-semibold text-gray-900">Gender</h1>
				<p className="mt-2 text-sm text-gray-700">A list of all the Genders.</p>
			</div>
			<div className="mt-4 flex">
				<Link
					onClick={() => handleDrawer("add", "", {})}
					type="button"
					className="inline-flex items-center justify-center rounded-md bbt bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ml-auto"
				>
					Add Gender
				</Link>
			</div>

			{isLoading ? (
				<FallingLinesLoader />
			) : (
				<Table
					columns={gender_columns({ onDeleteOpen, handleDrawer })}
					data={genderList?.length > 0 && !isLoading ? genderList : []}
					is_toggle={false}
				/>
			)}

			<div>
				<Transition.Root show={modalOpenFlage} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						onClose={setmodalOpenFlage}
					>
						<div className="fixed inset-0" />
						<div className="fixed inset-0 overflow-hidden">
							<div className="absolute inset-0 overflow-hidden">
								<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
									<Transition.Child
										as={Fragment}
										enter="transform transition ease-in-out duration-500 sm:duration-700"
										enterFrom="translate-x-full"
										enterTo="translate-x-0"
										leave="transform transition ease-in-out duration-500 sm:duration-700"
										leaveFrom="translate-x-0"
										leaveTo="translate-x-full"
									>
										<Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
											<form
												onSubmit={(e) => {
													e.preventDefault();
													handleSubmit(e);
												}}
												className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
											>
												<div className="h-0 flex-1 overflow-y-auto">
													<div className="bg-cyan-700 py-6 px-4 sm:px-6">
														<div className="flex items-center justify-between">
															<Dialog.Title className="text-lg font-medium text-white">
																{gender_id ? "Update" : "Add"} Gender
															</Dialog.Title>
															<div className="ml-3 flex h-7 items-center">
																<button
																	type="button"
																	className="rounded-md bg-cyan-700 text-cyan-200 hover:text-white"
																	onClick={() => setmodalOpenFlage(false)}
																>
																	<span className="sr-only">Close panel</span>
																	<XMarkIcon
																		className="h-6 w-6"
																		aria-hidden="true"
																	/>
																</button>
															</div>
														</div>
													</div>
													<div className="flex flex-1 flex-col justify-between">
														<div className="p-4 sm:p-6">
															<div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
																<div>
																	<label
																		htmlFor="gender_name_en"
																		className="block text-sm font-medium text-gray-900"
																	>
																		Gender Name English
																	</label>
																</div>
																<div className="mt-1 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.gender_name_en}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Gender Name En"
																		name="gender_name_en"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.gender_name_en &&
																	touched.gender_name_en ? (
																		<p className="text-red-600 text-sm">
																			{errors.gender_name_en}
																		</p>
																	) : null}
																</div>
															</div>

                                                            <div className="mt-1 sm:col-span-2 sm:mt-0 mb-2">
																<div>
																	<label
																		htmlFor="gender_name_en"
																		className="block text-sm font-medium text-gray-900"
																	>
																		Gender Name Arabic
																	</label>
																</div>
																<div className="mt-1 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.gender_name_ar}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Gender Name Ar"
																		name="gender_name_ar"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.gender_name_ar &&
																	touched.gender_name_ar ? (
																		<p className="text-red-600 text-sm">
																			{errors.gender_name_ar}
																		</p>
																	) : null}
																</div>
															</div>

														</div>
													</div>
												</div>
												<div className="flex flex-shrink-0 justify-end px-4 py-4">
													<button
														type="button"
														className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
														onClick={() => {
															setmodalOpenFlage(false);
														}}
													>
														Cancel
													</button>
													<button
														type="submit"
														className="ml-4 inline-flex justify-center rounded-md bbt bg-cyan-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
													>
														{gender_id ? "Update" : "Add"}
													</button>
												</div>
											</form>
										</Dialog.Panel>
									</Transition.Child>
								</div>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			</div>
		</div>
	);
}
export default GenderList;
