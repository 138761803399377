import React, { useState, useEffect, Fragment, useRef } from 'react';
import Editor from '@monaco-editor/react';

const SettingApiDoc = ({ apiDocData, setApiDocData }) => {
    const editorRef = useRef(null);
    const handleEditorDidMount = (editor, monaco) => {
        editorRef.current = editor;
        console.log("editor.getValue()=>", editor.getValue())
        editor.onDidChangeModelContent(() => {
            // Update configData state when the editor content changes
            // setApiDocData(editor.getValue());
        });
    }
    useEffect(() => {
        // Update editor value when apiDocData changes
        if (editorRef.current && apiDocData !== editorRef.current.getValue()) {
            editorRef.current.setValue(apiDocData);
        }
    }, [apiDocData]);
    const handleEditorChange = (value, event) => {
        console.log("Editor value changed:", value);
        setApiDocData(value);
    };
    return (
        <div className="mb-4 mt-5">
            <label htmlFor="setting_api_doc" className="block text-sm font-medium leading-6 text-gray-900">
                API Documentation
            </label>
            <div>
                <Editor
                    height="70vh"
                    defaultLanguage="json"
                    value={apiDocData} 
                    onMount={handleEditorDidMount}
                    onChange={handleEditorChange} // Attach onChange event listener
                    className="border bg-slate-400"
                />

            </div>
        </div>
    );
};

export default SettingApiDoc;