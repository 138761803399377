/* eslint-disable */
import Api from "./Api";
export default {

  async SavePlan(payload) {
    const response = await Api().post(`api/dashboard/plan`,payload);
    return response;
  },

  async getAllPlan(payload) {
    const response = await Api().get(`api/dashboard/plan?search=${payload}`);
    return response;
  },
  
  async getSinglePlan(payload) {
    const response = await Api().get(`api/dashboard/plan/${payload}`);
    return response;
  },

  async setPlanStatus(payload) {
    const response = await Api().post(`api/dashboard/plan/status`, payload);
    return response;
  },

  async deletePlan(payload) {
    const response = await Api().post(`api/dashboard/plan/remove`, payload);
    return response;
  },

};
