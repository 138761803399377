import * as Yup from "yup";
export const settingSchema = Yup.object({
  setting_title: Yup.string().min(3).max(100).required("Please Enter the Setting Name").nullable().typeError('Please Enter the Setting Name'),
});
export const priceSchema = Yup.object({
  price_plan_name: Yup.string().min(3).max(100).required("Please Enter the Plan Name").nullable().typeError('Please Enter the Plan Name'),
  price_amt: Yup.string()
    .min(0)
    .required("Please Enter the Price").nullable().typeError('Please Enter the Price'),
  price_short_desc: Yup.string().min(2).max(100).required("please Enter the Short Desc"),
  price_long_desc: Yup.string().min(2).max(100).required("please Enter the Long Desc"),
});
export const customerSchema = Yup.object({
  customer_first_name: Yup.string().min(3).max(100).required("Please Enter the Customer First Name").nullable().typeError('Please Enter the Customer First Name')
  ,
  customer_last_name: Yup.string().min(3).max(100).required("Please Enter the Customer Last Name").nullable().typeError('Please Enter the Customer Last Name'),
  customer_username: Yup.string().min(1).max(100).required("Please Enter the Customer Username").nullable().typeError('Please Enter the Customer Username'),
  customer_email: Yup.string().min(2).max(100).required("Please Enter the email").nullable().typeError('Please Enter the email'),
  customer_mobile: Yup.string()
    .min(10)
    .max(10)
    .required("Please Enter the Mobile Number").nullable().typeError('Please Enter the Mobile Number'),
  customer_address: Yup.string().min(2).max(100).required("please Enter the Customer Address"),
  customer_dob: Yup.date()
    .nullable()
    .required('Please Enter the Date Of Birth').typeError('Please Enter the Date Of Birth'),
  customer_designation: Yup.string().min(1).max(100).required("Please Enter the Customer Designation").nullable().typeError('Please Enter the Customer Designation'),
});
export const contactUsSchema = Yup.object({
  conus_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the User Name"),
  conus_email: Yup.string().email().required("Please Enter the Email address"),
  conus_mobile: Yup.string()
    .min(10)
    .max(10)
    .required("Please Enter the Mobile Number"),
  conus_question: Yup.string().required("please Enter User Designation"),
  conus_description: Yup.string().required("please Enter Feedback Comment"),
});
export const feedbackSchema = Yup.object({
  fb_username: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Feedback User Name"),
  fb_designation: Yup.string().required("please Enter User Designation"),
  fb_comment: Yup.string().required("please Enter Feedback Comment"),
  fb_star_rating: Yup.string().required("please Enter Star Rating"),
});
export const faqSchema = Yup.object({
  faq_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Faq Title"),
  faq_description: Yup.string().required("please Enter FAQ Description"),
  faq_is_position: Yup.number()
    .min(1)
    .max(999)
    .required("Please Enter the FAQ Position"),
});
export const domainSchema = Yup.object({
  domain_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Domain Name"),
  domain_url: Yup.string().required("please Enter Domain Url"),
});
export const apiKeySchema = Yup.object({
  ak_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Api Key Name"),
  ak_api_key: Yup.string().required("please Enter Api Key"),
  fk_domain_id: Yup.string().required("please Select Domain Name"),
});
export const apiSchema = Yup.object({
  api_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Api Name"),
  api_type: Yup.string().required("please Enter Api Type"),
  api_code: Yup.string().required("please Enter Api Code"),
});
export const subApiSchema = Yup.object({
  sapi_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Sub Api Name"),
  sapi_endpointe: Yup.string().required("please Enter Sub Api Enpointe"),
  sapi_code: Yup.string().required("please Enter Sub Api Code"),
});
export const planSchema = Yup.object({
  plan_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Plan Name"),
  plan_currency: Yup.string().required("please Enter Plan Currency"),
});
export const blogSchema = Yup.object({
  blog_title: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Blog Title"),
  blog_content: Yup.string().required("please Enter Blog Content"),
  blog_keyword: Yup.string().required("please Enter Blog Keyword"),
  blog_short_desc: Yup.string().required("please Enter Blog Short Description"),
  blog_long_desc: Yup.string().required("please Enter Blog Long Description"),
  // blog_is_published: Yup.boolean().required("Please Enter the FAQ Position"),
  blog_banner: Yup.mixed().test(
    "blog_banner",
    "Please Choose a Blog Banner",
    function (value) {
      const bannerPath = this.resolve(Yup.ref("blog_banner")); // Replace with actual path field name
      if (!bannerPath && !value) {
        return this.createError({
          message: "Blog Banner is required",
          path: "blog_banner",
        });
      }

      return true;
    }
  ),
});
export const userInfoSchema = Yup.object({
  user_first_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the First Name"),
  user_last_name: Yup.string()
    .min(2)
    .max(100)
    .required("please Enter the Last Name"),
  user_email: Yup.string().min(2).max(100).required("please Enter the email"),
});
export const genderSchema = Yup.object({
  gender_name_en: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Engllish gender"),
  gender_name_ar: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Arabic gender"),
});
export const stateSchema = Yup.object({
  state_name_en: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter English State Name"),
  state_name_ar: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter Arabic State Name"),
  fk_country_id: Yup.string().required("Please select country"),
});
export const citySchema = Yup.object({
  city_name_en: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter English City Name"),
  city_name_ar: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter Arabic City Name"),
  fk_state_id: Yup.string().required("Please select state"),
});

export const roleSchema = Yup.object({
  role_name: Yup.string().min(2).max(50).required("Please Enter the Role Name"),
});

export const userSchema = Yup.object({
  user_first_name: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the First Name"),
  user_last_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Last Name"),
  user_email: Yup.string().email().required("Please Enter the Email address"),
  user_mobile: Yup.string()
    .min(10)
    .max(10)
    .required("Please Enter the Mobile Number"),
  user_password: Yup.string().min(6).required("Please Enter the Password"),
  // conf_password: Yup.string()
  // 	.required()
  // 	.oneOf([Yup.ref("user_password"), null], "Password must match"),
  // user_location_id: Yup.string().required("Please Enter the Location ID"),
  user_role: Yup.string().required("Please Enter the Role"),
});

export const profileSchema = Yup.object({
  user_password: Yup.string().min(6).nullable().required("Please Enter the Password"),
  conf_password: Yup.string().nullable()
    .required()
    .oneOf([Yup.ref("user_password"), null], "Password must match"),
});







