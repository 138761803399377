import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { blogSchema } from "../../../../schemas";
import Table from "../../../../components/tables/table";
import { blog_columns } from "../../../../components/tables/tableheader";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toast";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../../helper/commonHelper";
import { FaSpinner } from "react-icons/fa";
import BlogServices from "../../../../ApiServices/BlogServices"

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}
function userStatusToggle(...classes) {
	return classes.filter(Boolean).join(" ");
}

function BlogList() {
	const pages = [{ title: "Blog List", href: "/blog", module_id: 3 }];
	const [isLoading, setIsLoading] = useState(true);
	const [modalOpenFlage, setmodalOpenFlage] = useState(false);
	const [blogList, setBlogList] = useState([]);
	const [banner, setBanner] = useState(null);
	const [tempBanner, setTempBanner] = useState(null);
	const [base64banner, setBase64banner] = useState(null);
	const [enabled, setEnabled] = useState(false)
	const [blog_id, setBlogid] = useState(0);
	const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		blog_id: null,
	});
	const navigate = useNavigate();
	const [disableSubmitButton, setDisableSubmitButton] = useState(false);

	const [allowedActions, setAllowedActions] = useState([]);
	useEffect(() => {
		const permissions = JSON.parse(localStorage.getItem("user_permissions"));
		if (permissions && window.location.pathname) {
			const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
			const actions = getAllowedActions(permissions, window.location.pathname);
			setAllowedActions(actions);
		}
	}, []);

	useEffect(() => {
		document.title = "Fire  • Blogs";
		getAllBlogs(3);
	}, []);
	const getAllBlogs = async (payload) => {
		setIsLoading(true);
		try {
			const res = await BlogServices.getAllBlog(payload)
			if (res.status) {
				setBlogList(res.data.data);
			}
			setIsLoading(false);
		} catch (e) {
			console.log(e, "error in getAllBlogs")
			toast.error("Fatal Error")
		}
	}

	const initialValues = {
		blog_id: "",
		blog_banner: "",
		blog_title: "",
		blog_content: "",
		blog_slug: "",
		blog_keyword: "",
		blog_short_desc: "",
		blog_long_desc: "",
		blog_is_published: false,
	};

	const [formBlog, setFormBlog] = useState(initialValues);

	const handleDrawer = (type, id, obj) => {
		if ((type === "edit", id)) {
			setBlogid(id);
			setEnabled(obj?.blog_is_published)
			setBase64banner(null)
			const initialValues = {
				blog_id: obj.blog_id,
				blog_banner: obj.blog_banner,
				blog_title: obj.blog_title,
				blog_content: obj.blog_content,
				blog_slug: obj.blog_slug,
				blog_keyword: obj.blog_keyword,
				blog_short_desc: obj.blog_short_desc,
				blog_long_desc: obj.blog_long_desc,
				blog_is_published: obj.blog_is_published,
			};
			setFormBlog(initialValues);
			setTempBanner(obj.blog_banner)
		} else {
			setBlogid("");
			setBanner(null)
			setFormBlog(initialValues);
		}
		if (modalOpenFlage === false) {
			setmodalOpenFlage(true);
		}
	};
	const onFileChange = (e) => {
		const file = e.target.files[0];
		const fileName = file.name;

		const reader = new FileReader();
		reader.onload = (e) => {
			setBanner(file)
			setTempBanner(e.target.result)
			setBase64banner(e.target.result)
		};
		reader.readAsDataURL(file);
	};
	const onDeleteOpen = (blog_id) => {
		setConfirmationModal({ blog_id, status: true });
	};

	const onDeleteBlog = async (blog_id) => {
		setIsLoading(true);
		let { data, status } = await BlogServices.deleteBlog({ blog_id })
		if (status === 200) {
			setIsLoading(false);
			if (data.status) {
				toast.success(data.message);
				getAllBlogs(3);
			}
		} else {
			toast.error(data.message);
		}
		setIsLoading(false);
		setConfirmationModal({ blog_id: null, status: false });
	};


	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			enableReinitialize: true,
			initialValues: formBlog,
			validationSchema: blogSchema,
			onSubmit: async (values, action) => {
				console.log("Submit Clicked", values);
				let body = {
					blog_id: blog_id,
					blog_title: values.blog_title,
					blog_content: values.blog_content,
					blog_slug: values.blog_slug,
					blog_keyword: values.blog_keyword,
					blog_short_desc: values.blog_short_desc,
					blog_long_desc: values.blog_long_desc,
					blog_is_published: enabled,
					blog_banner: base64banner,
				};
				if (blog_id === undefined || blog_id === null || blog_id === "") {
					setIsLoading(true);
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					await upsertBlog(body);
				} else {
					setIsLoading(true);
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					await upsertBlog(body);
				}
				action.resetForm();
				getAllBlogs(3);
				if (modalOpenFlage === true) {
					setTempBanner(null)
					getAllBlogs(3);
					setmodalOpenFlage(false);
					setDisableSubmitButton(false);
				}
				navigate("/blog");
			},
		});
	const upsertBlog = async (payload) => {
		let { data, status } = await BlogServices.SaveBlog(payload)
		if (status === 200) {
			if (data.status) {
				toast.success(data.message);
			}
		} else {
			toast.error(data.message);
		}
		setIsLoading(false);
	}
	return (
		<div className="px-4 sm:px-4 lg:px-4">
			<ConfirmationModal
				title={'Are you sure you want to delete this blog?'}
				open={confirmationModal.status}
				setOpen={setConfirmationModal}
				onDelete={() => onDeleteBlog(confirmationModal.blog_id)}
			/>
			<Breadcrumb pages={pages} />
			<div className="">
				<h1 className="text-xl font-semibold text-gray-900">Blog List</h1>
			</div>
			<div className="mt-4 flex">
				{allowedActions.filter((data) => data.permission_id == 34)?.length > 0 ? (
					<Link
						onClick={() => handleDrawer("add", "", {})}
						type="button"
						className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
					>
						Add Blog
					</Link>
				) : null}
			</div>

			{isLoading ? (
				<FallingLinesLoader />
			) : (
				<Table
					columns={blog_columns({ onDeleteOpen, handleDrawer, allowedActions })}
					data={blogList}
					is_toggle={false}
				/>
			)}

			<div>
				<Transition.Root show={modalOpenFlage} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						onClose={setmodalOpenFlage}
					>
						<div className="fixed inset-0" />
						<div className="fixed inset-0 overflow-hidden">
							<div className="absolute inset-0 overflow-hidden">
								<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
									<Transition.Child
										as={Fragment}
										enter="transform transition ease-in-out duration-500 sm:duration-700"
										enterFrom="translate-x-full"
										enterTo="translate-x-0"
										leave="transform transition ease-in-out duration-500 sm:duration-700"
										leaveFrom="translate-x-0"
										leaveTo="translate-x-full"
									>
										<Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
											<form
												onSubmit={(e) => {
													e.preventDefault();
													console.log(e);
													handleSubmit(e);
												}}
												className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
											>
												<div className="h-0 flex-1 overflow-y-auto">
													<div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9%    to-gray-900 to-80%  py-6 px-4 sm:px-6">
														<div className="flex items-center justify-between">
															<Dialog.Title className="text-lg font-medium text-white">
																{blog_id > 0 ? "Update" : "Add"} Blog
															</Dialog.Title>
															<div className="ml-3 flex h-7 items-center">
																<button
																	type="button"
																	className="rounded-md bg-gradient-to-b from-[#11ffdb] to-[#0172fd] text-white hover:text-white"
																	onClick={() => setmodalOpenFlage(false)}
																>
																	<span className="sr-only">Close panel</span>
																	<XMarkIcon
																		className="h-6 w-6"
																		aria-hidden="true"
																	/>
																</button>
															</div>
														</div>
													</div>
													<div className="flex flex-1 flex-col justify-between">
														<div className="divide-y divide-gray-200 p-4 sm:p-6">
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="blog_title"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Blog Title
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.blog_title}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Blog Title"
																		name="blog_title"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.blog_title &&
																		touched.blog_title ? (
																		<p className="text-red-600 text-sm">
																			{errors.blog_title}
																		</p>
																	) : null}
																</div>
															</div>

															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="blog_keyword"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Blog Keyword
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.blog_keyword}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Blog Keyword"
																		name="blog_keyword"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.blog_keyword &&
																		touched.blog_keyword ? (
																		<p className="text-red-600 text-sm">
																			{errors.blog_keyword}
																		</p>
																	) : null}
																</div>
															</div>

															<div className="my-2 sm:col-span-2 sm:mt-0">
																<div className="basis-1/2 overflow-auto">
																	<label htmlFor="blog_banner" className="block text-sm font-medium text-gray-700">
																		Banner
																	</label>
																	<input
																		onChange={(e) => {
																			handleChange(e);
																			onFileChange(e);
																		}}
																		onBlur={handleBlur}
																		type="file"
																		name="blog_banner"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.blog_banner &&
																		touched.blog_banner ? (
																		<p className="text-red-600 text-sm">
																			{errors.blog_banner}
																		</p>
																	) : null}
																</div>
																<div className="col-span-full">
																	{tempBanner ? (
																		<img
																			className="shadow-sm mt-4 w-40"
																			src={tempBanner}
																			alt="preview-banner"
																		/>
																	) : null}
																</div>
															</div>
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="blog_content"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Blog Content
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<textarea
																		rows="2"
																		value={values.blog_content}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Blog Content"
																		name="blog_content"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.blog_content &&
																		touched.blog_content ? (
																		<p className="text-red-600 text-sm">
																			{errors.blog_content}
																		</p>
																	) : null}
																</div>
															</div>

															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="blog_short_desc"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Blog Short Description
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<textarea
																		rows="2"
																		value={values.blog_short_desc}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Blog Short Description"
																		name="blog_short_desc"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.blog_short_desc &&
																		touched.blog_short_desc ? (
																		<p className="text-red-600 text-sm">
																			{errors.blog_short_desc}
																		</p>
																	) : null}
																</div>
															</div>
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="blog_long_desc"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Blog Long Description
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<textarea
																		rows="2"
																		value={values.blog_long_desc}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Blog Long Description"
																		name="blog_long_desc"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.blog_long_desc &&
																		touched.blog_long_desc ? (
																		<p className="text-red-600 text-sm">
																			{errors.blog_long_desc}
																		</p>
																	) : null}
																</div>
															</div>

															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="blog_is_position"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Blog Published {enabled}
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<Switch
																		checked={enabled}
																		onChange={setEnabled}
																		className={userStatusToggle(
																			enabled ? "bg-[#00d4f6]" : "bg-gray-200",
																			"relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
																		)}
																	>
																		<span className="sr-only">Use setting</span>
																		<span
																			aria-hidden="true"
																			className={userStatusToggle(
																				enabled ? "translate-x-5" : "translate-x-0",
																				"pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
																			)}
																		/>
																	</Switch>
																</div>
															</div>

														</div>
													</div>
												</div>
												<div className="flex flex-shrink-0 justify-end px-4 py-4">
													<button

														type="button"
														className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
														onClick={() => {
															setmodalOpenFlage(false);
														}}
													>
														Cancel
													</button>
													<button
														disabled={disableSubmitButton}
														type="submit"
														className="ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b from-[#11ffdb] to-[#0172fd] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
													>
														{blog_id > 0 ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Add")}

													</button>
												</div>
											</form>
										</Dialog.Panel>
									</Transition.Child>
								</div>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			</div>
		</div>
	);
}
export default BlogList;
