/* eslint-disable */
import Api from "./Api";
export default {

  async SaveApiKey(payload) {
    const response = await Api().post(`api/dashboard/api-key`,payload);
    return response;
  },

  async getAllApiKey(payload) {
    const response = await Api().get(`api/dashboard/api-key?search=${payload}`);
    return response;
  },
  
  async getSingleApiKey(payload) {
    const response = await Api().get(`api/dashboard/api-key/${payload}`);
    return response;
  },

  async setApiKeyStatus(payload) {
    const response = await Api().post(`api/dashboard/api-key/status`, payload);
    return response;
  },

  async deleteApiKey(payload) {
    const response = await Api().post(`api/dashboard/api-key/remove`, payload);
    return response;
  },

};
