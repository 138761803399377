/* eslint-disable */
import Api from "./Api";
export default {

  async SaveFaq(payload) {
    const response = await Api().post(`api/dashboard/faqs`,payload);
    return response;
  },

  async getAllFaq(payload) {
    const response = await Api().get(`api/dashboard/faqs?search=${payload}`);
    return response;
  },
  
  async getSingleFaq(payload) {
    const response = await Api().get(`api/dashboard/faqs/${payload}`);
    return response;
  },

  async setFaqStatus(payload) {
    const response = await Api().post(`api/dashboard/faqs/status`, payload);
    return response;
  },

  async deleteFaq(payload) {
    const response = await Api().post(`api/dashboard/faqs/remove`, payload);
    return response;
  },

};
