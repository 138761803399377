/* eslint-disable */
import Api from "./Api";
export default {
  async saveCustomer(payload) {
    const response = await Api().post(`api/dashboard/customer/`, payload);
    return response;
  },
  async getAllCustomer(payload) {
    const response = await Api().get(`api/dashboard/customer/?search=${payload}`);
    return response;
  },
  async getSingleCustomer(payload) {
    const response = await Api().get(`api/dashboard/customer/${payload}`);
    return response;
  },
  async deleteCustomer(payload) {
    const response = await Api().post(`api/dashboard/customer/remove/`, payload);
    return response;
  },

};
