import React from 'react';

const SettingDesclaimer = ({ values, handleChange, handleBlur, errors, touched }) => {
  return (
    <div className="mb-4 mt-5">
      <label htmlFor="setting_desclaimer" className="block text-sm font-medium leading-6 text-gray-900">
        Desclaimer
      </label>
      <div>
        <textarea
          rows="5"
          value={values.setting_desclaimer}
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          placeholder="Enter Desclaimer"
          name="setting_desclaimer"
          autoComplete="off"
          className="block w-full rounded-md border-[1px] py-2 px-3 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:text-sm"
        />
        {errors.setting_desclaimer && touched.setting_desclaimer ? (
          <p className="text-red-600 text-sm mt-1">
            {errors.setting_desclaimer}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default SettingDesclaimer;