import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { apiKeySchema } from "../../../../schemas";
import Table from "../../../../components/tables/table";
import { apiKey_columns } from "../../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toast";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../../helper/commonHelper";
import { FaSpinner } from "react-icons/fa";
import ApiKeyServices from "../../../../ApiServices/ApiKeyServices"
import DomainServices from "../../../../ApiServices/DomainServices"
import Select from "react-select";
import makeAnimated from 'react-select/animated';

function ApiKeyList() {
	const pages = [{ title: "ApiKey List", href: "/api-key", module_id: 3 }];
	const [isLoading, setIsLoading] = useState(true);
	const [modalOpenFlage, setmodalOpenFlage] = useState(false);
	const [apiKeyList, setApiKeyList] = useState([]);
	const [domainList, setDomainList] = useState([]);
	const [ak_id, setApiKeyid] = useState(0);
	const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		ak_id: null,
	});
	const navigate = useNavigate();
	const [disableSubmitButton, setDisableSubmitButton] = useState(false);
	const [selectedDomainId, setSelectedDomainId] = useState({ value: '', label: '' });
	const animatedComponents = makeAnimated();

	const [allowedActions, setAllowedActions] = useState([]);
	useEffect(() => {
		const permissions = JSON.parse(localStorage.getItem("user_permissions"));
		if (permissions && window.location.pathname) {
			const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
			const actions = getAllowedActions(permissions, window.location.pathname);
			setAllowedActions(actions);
		}
	}, []);

	useEffect(() => {
		document.title = "Fire  • ApiKeys";
		getAllApiKeys(3);
		getAllDomains(1);
	}, []);
	const getAllDomains = async (payload) => {
		setIsLoading(true);
		try {
			const res = await DomainServices.getAllDomain(payload)
			if (res.status) {
				setDomainList(res.data.data);
			}
			setIsLoading(false);
		} catch (e) {
			console.log(e, "error in getAllDomains")
			toast.error("Fatal Error")
		}
	}
	const getAllApiKeys = async (payload) => {
		setIsLoading(true);
		try {
			const res = await ApiKeyServices.getAllApiKey(payload)
			if (res.status) {
				setApiKeyList(res.data.data);
			}
			setIsLoading(false);
		} catch (e) {
			console.log(e, "error in getAllApiKeys")
			toast.error("Fatal Error")
		}
	}

	const initialValues = {
		ak_id: "",
		ak_name: "",
		ak_api_key: "",
		fk_domain_id: "",
		ak_is_active: "",
	};

	const [formApiKey, setFormApiKey] = useState(initialValues);

	const handleDrawer = (type, id, obj) => {
		setSelectedDomainId({ value: '', label: '' });
		if ((type === "edit", id)) {
			setApiKeyid(id);
			const initialValues = {
				ak_id: obj.ak_id,
				ak_name: obj.ak_name,
				ak_api_key: obj.ak_api_key,
				fk_domain_id: obj.fk_domain_id,
				ak_is_active: obj.ak_is_active,
			};
			if (Array.isArray(domainList) && domainList.length > 0) {
				let domain = domainList.filter(v => v.domain_id == initialValues.fk_domain_id)
				if (domain.length > 0) {
					setSelectedDomainId({ value: domain[0].domain_id, label: domain[0].domain_name })
				}
			}
			setFormApiKey(initialValues);
		} else {
			setApiKeyid("");
			setFormApiKey(initialValues);
		}
		if (modalOpenFlage === false) {
			setmodalOpenFlage(true);
		}
	};

	const onDeleteOpen = (ak_id) => {
		setConfirmationModal({ ak_id, status: true });
	};

	const onDeleteApiKey = async (ak_id) => {
		setIsLoading(true);
		let { data, status } = await ApiKeyServices.deleteApiKey({ ak_id })
		if (status === 200) {
			setIsLoading(false);
			if (data.status) {
				toast.success(data.message);
				getAllApiKeys(3);
			}
		} else {
			toast.error(data.message);
		}
		setIsLoading(false);
		setConfirmationModal({ ak_id: null, status: false });
	};


	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			enableReinitialize: true,
			initialValues: formApiKey,
			validationSchema: apiKeySchema,
			onSubmit: async (values, action) => {
				console.log("Submit Clicked", values);
				let body = {
					ak_id: ak_id,
					ak_is_active: true,
					ak_name: values.ak_name,
					ak_api_key: values.ak_api_key,
					fk_domain_id: values.fk_domain_id,
				};
				if (ak_id === undefined || ak_id === null || ak_id === "") {
					setIsLoading(true);
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					await upsertApiKey(body);
				} else {
					setIsLoading(true);
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					await upsertApiKey(body);
				}
				action.resetForm();
				getAllApiKeys(3);
				if (modalOpenFlage === true) {
					getAllApiKeys(3);
					setmodalOpenFlage(false);
					setDisableSubmitButton(false);
				}
				navigate("/api-key");
			},
		});
	const upsertApiKey = async (payload) => {
		let { data, status } = await ApiKeyServices.SaveApiKey(payload)
		if (status === 200) {
			if (data.status) {
				toast.success(data.message);
			} else {
				toast.error(data.message);
			}
		} else {
			toast.error(data.message);
		}
		setIsLoading(false);
	}
	return (
		<div className="px-4 sm:px-4 lg:px-4">
			<ConfirmationModal
				title={'Are you sure you want to delete this Api Key?'}
				open={confirmationModal.status}
				setOpen={setConfirmationModal}
				onDelete={() => onDeleteApiKey(confirmationModal.ak_id)}
			/>
			<Breadcrumb pages={pages} />
			<div className="">
				<h1 className="text-xl font-semibold text-gray-900">ApiKey List</h1>
			</div>
			<div className="mt-4 flex">
				{allowedActions.filter((data) => data.permission_id == 64)?.length > 0 ? (
					<Link
						onClick={() => handleDrawer("add", "", {})}
						type="button"
						className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
					>
						Add Api Key
					</Link>
				) : null}
			</div>

			{isLoading ? (
				<FallingLinesLoader />
			) : (
				<Table
					columns={apiKey_columns({ onDeleteOpen, handleDrawer, allowedActions })}
					data={apiKeyList}
					is_toggle={false}
				/>
			)}

			<div>
				<Transition.Root show={modalOpenFlage} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						onClose={setmodalOpenFlage}
					>
						<div className="fixed inset-0" />
						<div className="fixed inset-0 overflow-hidden">
							<div className="absolute inset-0 overflow-hidden">
								<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
									<Transition.Child
										as={Fragment}
										enter="transform transition ease-in-out duration-500 sm:duration-700"
										enterFrom="translate-x-full"
										enterTo="translate-x-0"
										leave="transform transition ease-in-out duration-500 sm:duration-700"
										leaveFrom="translate-x-0"
										leaveTo="translate-x-full"
									>
										<Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
											<form
												onSubmit={(e) => {
													e.preventDefault();
													console.log(e);
													handleSubmit(e);
												}}
												className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
											>
												<div className="h-0 flex-1 overflow-y-auto">
													<div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9%    to-gray-900 to-80%  py-6 px-4 sm:px-6">
														<div className="flex items-center justify-between">
															<Dialog.Title className="text-lg font-medium text-white">
																{ak_id > 0 ? "Update" : "Add"} ApiKey
															</Dialog.Title>
															<div className="ml-3 flex h-7 items-center">
																<button
																	type="button"
																	className="rounded-md bg-gradient-to-b from-[#11ffdb] to-[#0172fd] text-white hover:text-white"
																	onClick={() => setmodalOpenFlage(false)}
																>
																	<span className="sr-only">Close panel</span>
																	<XMarkIcon
																		className="h-6 w-6"
																		aria-hidden="true"
																	/>
																</button>
															</div>
														</div>
													</div>
													<div className="flex flex-1 flex-col justify-between">
														<div className="divide-y divide-gray-200 p-4 sm:p-6">
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="ak_name"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Api Key Name
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.ak_name}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Api Key Name"
																		name="ak_name"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.ak_name &&
																		touched.ak_name ? (
																		<p className="text-red-600 text-sm">
																			{errors.ak_name}
																		</p>
																	) : null}
																</div>
															</div>

															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="ak_api_key"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Api Key
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.ak_api_key}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Api Key"
																		name="ak_api_key"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.ak_api_key &&
																		touched.ak_api_key ? (
																		<p className="text-red-600 text-sm">
																			{errors.ak_api_key}
																		</p>
																	) : null}
																</div>
															</div>
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="ak_api_key"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Select Domain
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	{domainList && domainList.length > 0 ? (
																		<div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
																			<Select
																				name="domain_name"
																				id="domain_id"
																				menuPlacement="top"
																				placeholder={
																					<span>
																						Select Domain
																						<span className="text-red-600">*</span>
																					</span>
																				}
																				value={selectedDomainId}
																				onChange={(e) => {
																					console.log(e);
																					values.fk_domain_id = e.value;
																					setSelectedDomainId(e);
																				}}
																				components={animatedComponents}
																				options={
																					domainList
																						? domainList.map((v) => ({
																							value: v.domain_id,
																							label: v.domain_name,
																						}))
																						: []
																				}
																				classNamePrefix="select"
																				className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																			/>

																			{errors.fk_domain_id && touched.fk_domain_id ? (
																				<div className="text-sm text-red-600">
																					{errors.fk_domain_id.replace("fk_domain_id", "Domain Name")}
																				</div>
																			) : null}
																		</div>
																	) : null}

																</div>
															</div>



														</div>
													</div>
												</div>
												<div className="flex flex-shrink-0 justify-end px-4 py-4">
													<button

														type="button"
														className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
														onClick={() => {
															setmodalOpenFlage(false);
														}}
													>
														Cancel
													</button>
													<button
														disabled={disableSubmitButton}
														type="submit"
														className="ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b from-[#11ffdb] to-[#0172fd] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
													>
														{ak_id > 0 ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Add")}

													</button>
												</div>
											</form>
										</Dialog.Panel>
									</Transition.Child>
								</div>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			</div>
		</div>
	);
}
export default ApiKeyList;
