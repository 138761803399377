/* eslint-disable */
import Api from "./Api";
export default {

  async SaveApi(payload) {
    const response = await Api().post(`api/dashboard/master-api`,payload);
    return response;
  },

  async getAllApi(payload) {
    const response = await Api().get(`api/dashboard/master-api?search=${payload}`);
    return response;
  },
  
  async getSingleApi(payload) {
    const response = await Api().get(`api/dashboard/master-api/${payload}`);
    return response;
  },

  async setApiStatus(payload) {
    const response = await Api().post(`api/dashboard/master-api/status`, payload);
    return response;
  },

  async deleteApi(payload) {
    const response = await Api().post(`api/dashboard/master-api/remove`, payload);
    return response;
  },

};
