
import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const SettingTabs = ({ tabs, activeTab, onTabClick }) => {
  return (
    <div className="">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
          defaultValue={tabs.find((tab) => tab.current)}
        >
          {tabs.map((tab, index) => (
            <option key={tab}>{tab}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4 py-2 px-2 border-b-2" aria-label="Tabs">
          {tabs.map((tab, index) => (
            <a
              key={tab}
              onClick={() => onTabClick(index)}
              className={`cursor-pointer whitespace-nowrap px-2 py-2  text-sm font-medium ${activeTab === index ? 'border-indigo-500 rounded-md bg-indigo-100 text-indigo-700' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 '}`}
              aria-current={tab.current ? 'page' : undefined}
            >
              {tab}
            </a>
          ))}
        </nav>
      </div>
    </div>


  );
};

export default SettingTabs;
