import React, { useEffect, useState } from 'react'
import { FallingLinesLoader } from '../../../../components/spinners/Spinner'
import Table from '../../../../components/tables/table'
import Breadcrumb from '../../../../components/Breadcrumb'
import ConfirmationModal from '../../../../components/ConfirmationModal'
import CustomerServices from '../../../../ApiServices/CustomerServices'
import { toast } from 'react-toast'
import { customer_columns } from '../../../../components/tables/tableheader'
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../../helper/commonHelper";
import { Link, useNavigate } from "react-router-dom";

const Customer = () => {
  const pages = [{ title: "Customer List", href: "/customer", module_id: 40 }];
  const [isLoading, setIsLoading] = useState(false)
  const [customerList, setCustomerList] = useState([])
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    customer_id: null,
  });
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    getAllCustomers()
  }, [])

  const getAllCustomers = () => {
    setIsLoading(true)
    CustomerServices.getAllCustomer(3).then((res) => {
      const { data, status } = res;
      if (status) {
        console.log("firstdata", data.data)
        setCustomerList(data.data)
      }
      setIsLoading(false)
    }).catch((error) => {
      console.log(error)

    });
  }

  const onStatusUpdate = (customer_id, customer_is_active) => {
    let payload = {
      table_name: "customer",
      table_field: "customer_id",
      table_field_val: customer_id,
      table_status_field: "customer_is_active",
      table_status_val: customer_is_active ? false : true,
      table_text: "Customer",
      deleted_by_key: "customer_updated_by",
      delete_active_txt: customer_is_active ? " Deactivated" : " Activated"
    }
    CustomerServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getAllCustomers()
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }
  const onDeleteOpen = (customer_id, customer_is_active) => {
    console.log("customer_id", customer_id, customer_is_active)
    setConfirmationModal({ customer_id, status: true, customer_is_active });
  }
  const onDeleteItem = (customer_id) => {

    CustomerServices.deleteCustomer({ customer_id })
      .then((response) => {
        const { data, status } = response;
        console.log("response Prod=>", response)
        if (data.status) {
          setIsLoading(true)
          toast.success(data.message)
          setConfirmationModal({ customer_id: null, status: false });
          getAllCustomers()
          navigate("/customer");
        } else {
          toast.error(data.message)
        }
        setIsLoading(false)
      })
      .catch((error) => {
        // const msg = error.response.data.message;
        // toast.error(msg)
        toast.error("Sorry Fatal Error!")
        setIsLoading(false)
      });
  }
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteItem(confirmationModal.customer_id)}
      />
      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Customer</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the Customers.</p>
      </div>
      <div className="mt-4 flex">
        {allowedActions.filter((data) => data.permission_id == 46)?.length > 0 ? (
          <Link
            to={"/add-customer"}
            type="button"
            className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
          >
            Add Customer
          </Link>
        ) : null}
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <Table
          columns={customer_columns({ onDeleteOpen, onStatusUpdate, allowedActions })}
          data={customerList ? customerList : []}
          is_toggle={false}
        />
      )}
    </div>
  )
}

export default Customer