
import React from 'react';

const SettingTitle = ({ values, handleChange, handleBlur, errors, touched }) => {
  return (
    <div className="mb-4 mt-5">
      <label htmlFor="setting_title" className="block text-sm font-medium leading-6 text-gray-900">
        Setting Name<span className="text-red-600">*</span>
      </label>
      <div className="">
        <input
          type="text"
          name="setting_title"
          id="setting_title"
          value={values.setting_title}
          onChange={handleChange}
          onBlur={handleBlur}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
          placeholder="Setting Name"
        />
        {errors.setting_title && touched.setting_title ? (
          <div className="text-sm text-red-600">
            {errors.setting_title}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SettingTitle;