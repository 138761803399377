import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { planSchema } from "../../../../schemas";
import Table from "../../../../components/tables/table";
import { plan_columns } from "../../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toast";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../../helper/commonHelper";
import { FaSpinner } from "react-icons/fa";
import PlanServices from "../../../../ApiServices/PlanServices"
import ApiServices from "../../../../ApiServices/ApiServices"
import Select from "react-select";
import makeAnimated from 'react-select/animated';
import { Switch } from '@headlessui/react';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

function PlanMasterList() {
	const pages = [{ title: "Plan List", href: "/sub-api", module_id: 3 }];
	const [isLoading, setIsLoading] = useState(true);
	const [modalOpenFlage, setmodalOpenFlage] = useState(false);
	const [planList, setPlanList] = useState([]);
	const [apiList, setApiList] = useState([]);
	const [plan_id, setPlanid] = useState(0);
	const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		plan_id: null,
	});
	const [plan_is_tops, setPlanIsTops] = useState(false);
	const navigate = useNavigate();
	const [disableSubmitButton, setDisableSubmitButton] = useState(false);
	const [selectedApiId, setSelectedApiId] = useState({ value: '', label: '' });
	const animatedComponents = makeAnimated();

	const [allowedActions, setAllowedActions] = useState([]);
	useEffect(() => {
		const permissions = JSON.parse(localStorage.getItem("user_permissions"));
		if (permissions && window.location.pathname) {
			const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
			const actions = getAllowedActions(permissions, window.location.pathname);
			setAllowedActions(actions);
		}
	}, []);

	useEffect(() => {
		document.title = "Fire  • Plan";
		getAllPlans(3);
		getAllApis(1);
	}, []);
	const getAllApis = async (payload) => {
		setIsLoading(true);
		try {
			const res = await ApiServices.getAllApi(payload)
			if (res.status) {
				setApiList(res.data.data);
			}
			setIsLoading(false);
		} catch (e) {
			console.log(e, "error in getAllApis")
			toast.error("Fatal Error")
		}
	}
	const getAllPlans = async (payload) => {
		setIsLoading(true);
		try {
			const res = await PlanServices.getAllPlan(payload)
			if (res.status) {
				setPlanList(res.data.data);
			}
			setIsLoading(false);
		} catch (e) {
			console.log(e, "error in getAllPlans")
			toast.error("Fatal Error")
		}
	}

	const initialValues = {
		plan_id: 0,
		plan_name: "",
		plan_currency: "",
		plan_yearly_price: "",
		plan_descp: "",
		plan_monthly_price: "",
		fk_api_id: "",
		plan_is_active: true,
		plan_is_tops: false,
	};

	const [formPlan, setFormPlan] = useState(initialValues);

	const handleDrawer = (type, id, obj) => {
		setSelectedApiId({ value: '', label: '' });
		if ((type === "edit", id)) {
			setPlanid(id);
			const initialValues = {
				plan_id: obj.plan_id,
				plan_name: obj.plan_name,
				plan_currency: obj.plan_currency,
				plan_yearly_price: obj.plan_yearly_price,
				plan_descp: obj.plan_descp,
				plan_monthly_price: obj.plan_monthly_price,
				fk_api_id: obj.fk_api_id,
				plan_is_active: obj.plan_is_active,
				plan_is_tops: obj.plan_is_tops
			};
			if (Array.isArray(apiList) && apiList.length > 0) {
				let domain = apiList.filter(v => v.api_id == initialValues.fk_api_id)
				if (domain.length > 0) {
					setSelectedApiId({ value: domain[0].api_id, label: domain[0].api_name })
				}
			}
			setFormPlan(initialValues);
		} else {
			setPlanid("");
			setFormPlan(initialValues);
		}
		if (modalOpenFlage === false) {
			setmodalOpenFlage(true);
		}
	};

	const onDeleteOpen = (plan_id) => {
		setConfirmationModal({ plan_id, status: true });
	};

	const onDeletePlan = async (plan_id) => {
		setIsLoading(true);
		let { data, status } = await PlanServices.deletePlan({ plan_id })
		if (status === 200) {
			setIsLoading(false);
			if (data.status) {
				toast.success(data.message);
				getAllPlans(3);
			}
		} else {
			toast.error(data.message);
		}
		setIsLoading(false);
		setConfirmationModal({ plan_id: null, status: false });
	};


	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			enableReinitialize: true,
			initialValues: formPlan,
			validationSchema: planSchema,
			onSubmit: async (values, action) => {
				console.log("Submit Clicked", values);
				let body = {
					plan_id: plan_id,
					plan_is_active: true,
					plan_name: values.plan_name,
					plan_currency: values.plan_currency,
					plan_yearly_price: values.plan_yearly_price,
					plan_descp: values.plan_descp,
					plan_monthly_price: values.plan_monthly_price,
					fk_api_id: values.fk_api_id,
					plan_is_tops: values.plan_is_tops,
				};
				if (plan_id === undefined || plan_id === null || plan_id === "") {
					setIsLoading(true);
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					await upsertPlan(body);
				} else {
					setIsLoading(true);
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					await upsertPlan(body);
				}
				action.resetForm();
				getAllPlans(3);
				if (modalOpenFlage === true) {
					getAllPlans(3);
					setmodalOpenFlage(false);
					setDisableSubmitButton(false);
				}
				navigate("/plan");
			},
		});
	const upsertPlan = async (payload) => {
		let { data, status } = await PlanServices.SavePlan(payload)
		if (status === 200) {
			if (data.status) {
				toast.success(data.message);
			} else {
				toast.error(data.message);
			}
		} else {
			toast.error(data.message);
		}
		setIsLoading(false);
	}
	return (
		<div className="px-4 sm:px-4 lg:px-4">
			<ConfirmationModal
				title={'Are you sure you want to delete this Plan?'}
				open={confirmationModal.status}
				setOpen={setConfirmationModal}
				onDelete={() => onDeletePlan(confirmationModal.plan_id)}
			/>
			<Breadcrumb pages={pages} />
			<div className="">
				<h1 className="text-xl font-semibold text-gray-900">Plan List</h1>
			</div>
			<div className="mt-4 flex">
				{allowedActions.filter((data) => data.permission_id == 82)?.length > 0 ? (
					<Link
						onClick={() => handleDrawer("add", "", {})}
						type="button"
						className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
					>
						Add Plan
					</Link>
				) : null}
			</div>

			{isLoading ? (
				<FallingLinesLoader />
			) : (
				<Table
					columns={plan_columns({ onDeleteOpen, handleDrawer, allowedActions })}
					data={planList}
					is_toggle={false}
				/>
			)}

			<div>
				<Transition.Root show={modalOpenFlage} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						onClose={setmodalOpenFlage}
					>
						<div className="fixed inset-0" />
						<div className="fixed inset-0 overflow-hidden">
							<div className="absolute inset-0 overflow-hidden">
								<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
									<Transition.Child
										as={Fragment}
										enter="transform transition ease-in-out duration-500 sm:duration-700"
										enterFrom="translate-x-full"
										enterTo="translate-x-0"
										leave="transform transition ease-in-out duration-500 sm:duration-700"
										leaveFrom="translate-x-0"
										leaveTo="translate-x-full"
									>
										<Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
											<form
												onSubmit={(e) => {
													e.preventDefault();
													console.log(e);
													handleSubmit(e);
												}}
												className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
											>
												<div className="h-0 flex-1 overflow-y-auto">
													<div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9%    to-gray-900 to-80%  py-6 px-4 sm:px-6">
														<div className="flex items-center justify-between">
															<Dialog.Title className="text-lg font-medium text-white">
																{plan_id > 0 ? "Update" : "Add"} Plan
															</Dialog.Title>
															<div className="ml-3 flex h-7 items-center">
																<button
																	type="button"
																	className="rounded-md bg-gradient-to-b from-[#11ffdb] to-[#0172fd] text-white hover:text-white"
																	onClick={() => setmodalOpenFlage(false)}
																>
																	<span className="sr-only">Close panel</span>
																	<XMarkIcon
																		className="h-6 w-6"
																		aria-hidden="true"
																	/>
																</button>
															</div>
														</div>
													</div>
													<div className="flex flex-1 flex-col justify-between">
														<div className="divide-y divide-gray-200 p-4 sm:p-6">
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="plan_name"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Plan Name
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.plan_name}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Plan Name"
																		name="plan_name"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.plan_name &&
																		touched.plan_name ? (
																		<p className="text-red-600 text-sm">
																			{errors.plan_name}
																		</p>
																	) : null}
																</div>
															</div>

															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="plan_currency"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Currency
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.plan_currency}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Currency"
																		name="plan_currency"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.plan_currency &&
																		touched.plan_currency ? (
																		<p className="text-red-600 text-sm">
																			{errors.plan_currency}
																		</p>
																	) : null}
																</div>
															</div>
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="plan_yearly_price"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Yearly Price
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.plan_yearly_price}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="number"
																		placeholder="Enter Yearly Price"
																		name="plan_yearly_price"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.plan_yearly_price &&
																		touched.plan_yearly_price ? (
																		<p className="text-red-600 text-sm">
																			{errors.plan_yearly_price}
																		</p>
																	) : null}
																</div>
															</div>
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="plan_monthly_price"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Monthly Price
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.plan_monthly_price}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="number"
																		placeholder="Enter Monthly Price"
																		name="plan_monthly_price"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.plan_monthly_price &&
																		touched.plan_monthly_price ? (
																		<p className="text-red-600 text-sm">
																			{errors.plan_monthly_price}
																		</p>
																	) : null}
																</div>
															</div>
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="fk_api_id"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Select Api Name
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	{apiList && apiList.length > 0 ? (
																		<div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
																			<Select
																				name="api_name"
																				id="api_id"
																				menuPlacement="top"
																				placeholder={
																					<span>
																						Select Domain
																						<span className="text-red-600">*</span>
																					</span>
																				}
																				value={selectedApiId}
																				onChange={(e) => {
																					console.log(e);
																					values.fk_api_id = e.value;
																					setSelectedApiId(e);
																				}}
																				components={animatedComponents}
																				options={
																					apiList
																						? apiList.map((v) => ({
																							value: v.api_id,
																							label: v.api_name,
																						}))
																						: []
																				}
																				classNamePrefix="select"
																				className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																			/>

																			{errors.fk_api_id && touched.fk_api_id ? (
																				<div className="text-sm text-red-600">
																					{errors.fk_api_id.replace("fk_api_id", "Domain Name")}
																				</div>
																			) : null}
																		</div>
																	) : null}

																</div>
															</div>
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="plan_descp"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Plan Desc
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<textarea
																		value={values.plan_descp}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Plan Desc"
																		name="plan_descp"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.plan_descp &&
																		touched.plan_descp ? (
																		<p className="text-red-600 text-sm">
																			{errors.plan_descp}
																		</p>
																	) : null}
																</div>
															</div>
															<div class="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label htmlFor="plan_is_tops" className="block text-sm font-medium leading-6 text-gray-900">
																		Plan Is Top
																	</label></div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<Switch
																		checked={plan_is_tops}
																		onChange={setPlanIsTops}
																		className={classNames(
																			plan_is_tops ? 'bg-indigo-600' : 'bg-gray-200',
																			'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
																		)}
																	>
																		<span className="sr-only">Use setting</span>
																		<span
																			aria-hidden="true"
																			className={classNames(
																				plan_is_tops ? 'translate-x-5' : 'translate-x-0',
																				'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
																			)}
																		/>
																	</Switch>
																</div>
															</div>




														</div>
													</div>
												</div>
												<div className="flex flex-shrink-0 justify-end px-4 py-4">
													<button

														type="button"
														className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
														onClick={() => {
															setmodalOpenFlage(false);
														}}
													>
														Cancel
													</button>
													<button
														disabled={disableSubmitButton}
														type="submit"
														className="ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b from-[#11ffdb] to-[#0172fd] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
													>
														{plan_id > 0 ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Add")}

													</button>
												</div>
											</form>
										</Dialog.Panel>
									</Transition.Child>
								</div>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			</div>
		</div>
	);
}
export default PlanMasterList;
