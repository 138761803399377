/* eslint-disable */
import Api from "./Api";
export default {

  async SaveSubApi(payload) {
    const response = await Api().post(`api/dashboard/sub-api`, payload);
    return response;
  },

  async getAllSubApi(payload) {
    const response = await Api().get(`api/dashboard/sub-api?search=${payload}`);
    return response;
  },

  async getSingleSubApi(payload) {
    const response = await Api().get(`api/dashboard/sub-api/${payload}`);
    return response;
  },

  async setSubApiStatus(payload) {
    const response = await Api().post(`api/dashboard/sub-api/status`, payload);
    return response;
  },

  async deleteSubApi(payload) {
    const response = await Api().post(`api/dashboard/sub-api/remove`, payload);
    return response;
  },

};
