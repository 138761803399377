import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./protectedRoute";
import Login from "../container/pages/login/Login";
import UsersList from "../container/pages/users/Users";
import RoleList from "../container/pages/role/RoleList";
import Profile from "../container/pages/profile/Profile";
import GenderList from "../container/pages/gender/Gender";
import EditPermissions from "../container/pages/role/EditPermissions";

import Error from "../container/error/Error";
import Home from "../container/pages/home/Home";
import FaqList from "../container/pages/common_page/faq/FaqList";
import BlogList from "../container/pages/common_page/blog/BlogList";
import FeedbackList from "../container/pages/common_page/feedback/FeedbackList";
import ContactUsList from "../container/pages/common_page/contact_us/ContactUsList";
import CustomerList from "../container/pages/common_page/customer/Customer";
import AddCustomer from "../container/pages/common_page/customer/AddCustomer";
import PriceingList from "../container/pages/common_page/priceing/PriceingList";
import AddPriceing from "../container/pages/common_page/priceing/AddPriceing";
import AddSystem from "../container/pages/common_page/system/AddSystem";
import DomainList from "../container/pages/common_page/domain/DomainList";
import ApiKeyList from "../container/pages/common_page/apiKey/ApiKeyList";
import ApiMasterList from "../container/pages/common_page/apiMaster/ApiMasterList";
import SubApiMasterList from "../container/pages/common_page/subApiMaster/SubApiMasterList";
import PlanList from "../container/pages/common_page/plan/PlanList";

const RoutesConfig = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signUp" element={<Login />} />
        <Route path="*" element={<Error />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/user" element={<UsersList />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/user-list" element={<UsersList />} />

          <Route path="/role" element={<RoleList />} />
          <Route path="/role">
            <Route index element={<RoleList />} />
            <Route path=":role_id" element={<EditPermissions />} />
          </Route>
          <Route path="/gender" element={<GenderList />} />
          <Route path="/faq" element={<FaqList />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/feedback" element={<FeedbackList />} />
          <Route path="/contactus" element={<ContactUsList />} />
          <Route path="/price" element={<PriceingList />} />
          <Route path="/add-price/">
            <Route index element={<AddPriceing />} />
            <Route path=":price_id" element={<AddPriceing />} />
          </Route>

          <Route path="/customer" element={<CustomerList />} />
          <Route path="/add-customer/">
            <Route index element={<AddCustomer />} />
            <Route path=":customer_id" element={<AddCustomer />} />
          </Route>
          <Route path="/setting" element={<AddSystem />} />
          <Route path="/domain" element={<DomainList />} />
          <Route path="/api-key" element={<ApiKeyList />} />
          <Route path="/api" element={<ApiMasterList />} />
          <Route path="/sub-api" element={<SubApiMasterList />} />
          <Route path="/plan" element={<PlanList />} />
          {/* <Route path="/setting">
            <Route index path=":setting_id" element={<AddSystem />} />
          </Route> */}
        </Route>
      </Routes>
    </Router>
  );

};

export default RoutesConfig;
