import React, { useState, useEffect, Fragment, useRef } from 'react';
import Breadcrumb from '../../../../components/Breadcrumb';
import { settingSchema } from '../../../../schemas';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import SettingServices from '../../../../ApiServices/SettingServices';
import { Switch } from '@headlessui/react';
import { toast } from 'react-toast';
import { FaSpinner } from "react-icons/fa";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import SettingTabs from '../../../../container/pages/common_page/system/SettingTabs';
import SettingTitle from '../../../../container/pages/common_page/system/SettingTitle';
import SettingDescription from '../../../../container/pages/common_page/system/SettingDescription';
import SettingDesclaimer from '../../../../container/pages/common_page/system/SettingDesclaimer';
import SettingApiDoc from '../../../../container/pages/common_page/system/SettingApiDoc';
import SettingPrivacyPolicy from '../../../../container/pages/common_page/system/SettingPrivacyPolicy';
import SettingTermCondition from '../../../../container/pages/common_page/system/SettingTermCondition';
import Editor from '@monaco-editor/react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AddSystem = () => {
  const pages = [{ title: "Add Setting", href: "/setting", module_id: 40 }];
  const [setting_is_active, setSettingIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  // const { setting_id: setting_id } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [setting_id, setSettingId] = useState(0);
  const editorRef = useRef(null);
  const [apiDocData, setApiDocData] = useState("");

  useEffect(() => {
    getAllSystemSetting();
  }, []);
  // useEffect(() => {
  //   if (setting_id>0) {
  //     getSetting(setting_id);
  //   }
  // }, [setting_id]);

  let initialValues = {
    setting_id: "",
    setting_title: "",
    setting_logo: "",
    setting_desp: "",
    setting_term_condition: "",
    setting_privacy_policy: "",
    setting_desclaimer: "",
    setting_api_doc: "",
    setting_is_active: true,
  };
  const [settingForm, setSettingForm] = useState(initialValues);
  const getAllSystemSetting = () => {
    setIsLoading(true);
    SettingServices.getAllSetting(1).then((res) => {
      const { data, status } = res;
      if (status) {
        let settingDet = [];
        if (data?.data?.length > 0) {
          settingDet = data?.data[0];
          setIsLoading(true);
          getSetting(settingDet?.setting_id);
          setSettingId(settingDet?.setting_id);
        }

      }else{
        setIsLoading(false);
      }
      // setIsLoading(false);
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
    });
  };

  const getSetting = (setting_id) => {
    setIsLoading(true);
    SettingServices.getSingleSetting(setting_id).then((res) => {
      const { data, status } = res;
      if (status) {
        let settingDet = data?.data;
        initialValues = {
          setting_id: setting_id,
          setting_title: settingDet?.setting_title || "",
          setting_logo: settingDet?.setting_logo || "",
          setting_desp: settingDet?.setting_desp || "",
          setting_term_condition: settingDet?.setting_term_condition || "",
          setting_privacy_policy: settingDet?.setting_privacy_policy || "",
          setting_desclaimer: settingDet?.setting_desclaimer || "",
          setting_api_doc: apiDocData || {},
          setting_is_active: settingDet?.setting_is_active || false,
        };
        setApiDocData(JSON.stringify(JSON.parse(settingDet?.setting_api_doc), null, 2));
        setSettingForm(initialValues);
      }
      setIsLoading(false);
    }).catch((error) => {
      console.log(error);
      setIsLoading(false);
    });
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,
    initialValues: settingForm,
    validationSchema: settingSchema,
    onSubmit: async (values, action) => {
      let body = {
        setting_id: setting_id ? setting_id : null,
        setting_title: values?.setting_title || "",
        setting_logo: values?.setting_logo || "",
        setting_desp: values?.setting_desp || "",
        setting_term_condition: values?.setting_term_condition || "",
        setting_privacy_policy: values?.setting_privacy_policy || "",
        setting_desclaimer: values?.setting_desclaimer || "",
        setting_api_doc: apiDocData || "",
        setting_is_active: setting_is_active || false,
      };

      const response = await SettingServices.SaveSetting(body);
      if (response.data.status) {
        action.resetForm();
        setSettingForm(initialValues);
        toast.success(response.data.message);
        if (response?.data?.data?.setting_id) {
          navigate(`/setting/${response.data.data.setting_id}`);
        } else {
          navigate(`/setting/0`);
        }
      } else {
        toast.error(response.data.message);
      }
    },
  });

  const tabs = ["Setting Name", "Description", "Term & Condition", "Privacy Policy", "Desclaimer", "Api Doc", "Setting Active"];

  return (
    <>
      {!isLoading ? (
        <div>
          <Breadcrumb pages={pages} />
          <div className="bg-white ">
            <div className="">
              <SettingTabs tabs={tabs} activeTab={activeTab} onTabClick={setActiveTab} />
              <div className='px-4 py-3 sm:px-6'>
                <form onSubmit={handleSubmit}>
                  {activeTab === 0 && (
                    <SettingTitle
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                    />
                  )}
                  {activeTab === 1 && (
                    <SettingDescription
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                    />
                  )}
                  {activeTab === 2 && (
                    <SettingTermCondition
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                    />
                  )}
                  {activeTab === 3 && (
                    <SettingPrivacyPolicy
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                    />
                  )}
                  {activeTab === 4 && (
                    <SettingDesclaimer
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                    />
                  )}
                  {activeTab === 5 && (
                    <SettingApiDoc
                      apiDocData={apiDocData}
                      setApiDocData={setApiDocData}
                    // values={values}
                    // handleChange={handleChange}
                    // handleBlur={handleBlur}
                    // errors={errors}
                    // touched={touched}
                    />
                  )}
                  {activeTab === 6 && (
                    <div class="mb-4 mt-5">
                      <label htmlFor="setting_is_active" className="block text-sm font-medium leading-6 text-gray-900">
                        Setting Is Active
                      </label>
                      <Switch
                        checked={setting_is_active}
                        onChange={setSettingIsActive}
                        className={classNames(
                          setting_is_active ? 'bg-indigo-600' : 'bg-gray-200',
                          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                        )}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={classNames(
                            setting_is_active ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                          )}
                        />
                      </Switch>
                    </div>
                  )}
                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                      type="submit"
                      className="inline-flex items-center justify-center rounded-md bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm"
                    >
                      {setting_id ? "Update" : "Save"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <FallingLinesLoader />
      )}
    </>
  );
};

export default AddSystem;
