
import React from 'react';

const SettingPrivacyPolicy = ({ values, handleChange, handleBlur, errors, touched }) => {
  return (
    <div className="mb-4 mt-5">
      <label htmlFor="setting_privacy_policy" className="block text-sm font-medium leading-6 text-gray-900">
        Privacy Policy
      </label>
      <div>
        <textarea
          rows="5"
          value={values.setting_privacy_policy}
          onChange={handleChange}
          onBlur={handleBlur}
          type="text"
          placeholder="Enter Privacy Policy"
          name="setting_privacy_policy"
          autoComplete="off"
          className="block w-full rounded-md border-[1px] py-2 px-3 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:text-sm"
        />
        {errors.setting_privacy_policy && touched.setting_privacy_policy ? (
          <p className="text-red-600 text-sm mt-1">
            {errors.setting_privacy_policy}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default SettingPrivacyPolicy;