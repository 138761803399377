import { Link } from "react-router-dom";
import { capitalise } from "../../utilities/utilities";
import { Switch } from "@headlessui/react";
import "./style.css"; // import CSS file
import moment from "moment";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { CurrencyRupeeIcon, PlusIcon } from "@heroicons/react/20/solid";
import { PencilIcon, ChevronLeftIcon, ChevronRightIcon, TrashIcon } from "@heroicons/react/24/outline";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { BsCurrencyRupee } from "react-icons/bs";

import {
  calculateAge,
} from "../../helper/commonHelper";

import { FaDownload, FaSpinner } from 'react-icons/fa';

function userStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}

function magazineStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}
function magazineIssueStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}

function productStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}
export const user_columns = ({
  onDeleteOpen,
  onDeleteOpenSection,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => (i + 1),
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
    },
    {
      Header: "Name",
      accessor: (d) =>
        capitalise(d.user_first_name) + " " + capitalise(d.user_last_name),
    },
    {
      Header: "Email",
      accessor: "user_email",
    },
    {
      Header: "Mobile",
      accessor: "user_mobile",
    },
    {
      Header: "Role",
      accessor: (d) => capitalise(d.m_role_relation?.role_name),
    },
    {
      Header: "Status",
      accessor: (d) => (d.user_is_active == true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.user_id;
        const enabled = cell.row.original.user_is_active;
        const isBtn = cell.row.original.isBtn;

        if (
          allowedActions?.filter((data) => data.permission_id === 12)?.length >
          0 &&
          isBtn
        ) {
          return (
            <Switch
              checked={enabled}
              onChange={() => onDeleteOpen(cellId, enabled)}
              className={userStatusToggle(
                enabled ? "bg-[#00d4f6]" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
              )}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={userStatusToggle(
                  enabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
          );
        } else {
          // Add a default case or return null if nothing should be rendered
          return null;
        }
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.user_id;
        const isBtn = cell.row.original.isBtn;
        const user_is_deleted = cell.row.original.user_is_deleted;
        const getEditPermission = allowedActions.filter((data) => data.permission_id == 11);
        const getDeletePermission = allowedActions.filter((data) => data.permission_id == 13);
        const getUid = localStorage.getItem("user_id");
        let actionButtonStatus = false;
        if (((getEditPermission?.length > 0 && isBtn) || getUid == cellId) && (getDeletePermission?.length > 0 && isBtn)) {
          actionButtonStatus = true;
        }

        return (
          <>

            <span className="isolate inline-flex rounded-md shadow-sm">
              {(allowedActions.filter((data) => data.permission_id ==11)?.length > 0 && isBtn) || localStorage.getItem("user_id") == cellId ? (
                <button
                  onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                  type="button"
                  className={`relative inline-flex items-center ${actionButtonStatus ? 'rounded-l-md' : 'rounded-l-md rounded-r-md'} bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                >
                  <span className="sr-only">Edit</span>
                  <PencilIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}

              {(allowedActions.filter((data) => data.permission_id == 13)?.length > 0 && isBtn) ? (
                <button
                  onClick={() => onDeleteOpenSection(cellId, user_is_deleted)}
                  type="button"
                  className={`relative -ml-px inline-flex items-center ${actionButtonStatus ? 'rounded-r-md' : 'rounded-l-md rounded-r-md'} bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10`}
                >
                  <span className="sr-only">Delete</span>
                  <TrashIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              ) : null}
            </span>

          </>
        );
      },
    },
  ];

export const role_columns = ({
  onDeleteOpen,
  handleDrawer,
  allowedActions,
}) => [
    {
      Header: "SN",
      accessor: (d, i) => (i + 1),
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
    },
    {
      Header: "Role Id",
      accessor: "role_id",
    },
    {
      Header: "Role Name",
      accessor: (d) => capitalise(d.role_name),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.role_id;
        const isBtn = cell.row.original.isBtn;
        return (
          <>
            {/* {isBtn && ( */}
            {(allowedActions.filter((data) => data.permission_id == 4)?.length >
              0 && isBtn) || (localStorage.getItem("role_id") == 1) ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}

            {allowedActions.filter((data) => data.permission_id == 5)?.length >
              0 && isBtn ? (
              // {isBtn && (
              <Link
                to={`/role/${cellId}`}
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit Permissions
                </button>
              </Link>
            ) : null}

            {/* {isBtn && ( */}
            {allowedActions.filter((data) => data.permission_id == 6)?.length >
              0 && isBtn ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                </button>
              </span>
            ) : null}
          </>
        );
      },
    },
  ];

export const contactUs_columns = ({ onDeleteOpen, handleDrawer, allowedActions }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },
  {
    Header: "User Name",
    accessor: (d) => capitalise(d.conus_name),
  },
  {
    Header: "Email",
    accessor: (d) => capitalise(d.conus_email),
  },
  {
    Header: "Mobile No",
    accessor: "conus_mobile",
  },

  {
    Header: "Question",
    accessor: "conus_question",
  },
  {
    Header: "Description",
    accessor: "conus_description",
  },

  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.conus_id;
      return (
        <>
          {allowedActions.filter((data) => data.permission_id == 23)?.length >
            0 ? (
            <Link
              onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </button>
            </Link>) : null}

          {allowedActions.filter((data) => data.permission_id == 25)?.length >
            0 ? (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </button>
            </span>) : null}
        </>
      );
    },
  },
];

export const feedback_columns = ({ onDeleteOpen, handleDrawer, allowedActions }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },
  {
    Header: "User Name",
    accessor: (d) => capitalise(d.fb_username),
  },
  {
    Header: "Designation",
    accessor: (d) => capitalise(d.fb_designation),
  },
  {
    Header: "Comment",
    accessor: "fb_comment",
  },

  {
    Header: "Rating",
    accessor: "fb_star_rating",
    Cell: (cell) => {
      const rating = cell?.row?.original?.fb_star_rating;

      const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
          if (i <= rating) {
            stars.push(
              <span key={i} className="text-yellow-500">
                &#9733;
              </span>
            ); // Unicode star character
          } else {
            stars.push(
              <span key={i} className="text-gray-500">
                &#9734;
              </span>
            ); // Unicode empty star character
          }
        }
        return stars;
      };

      return (
        <span className="sm:text-sm text-gray-500">{renderStars(rating)}</span>
      );
    },
  },

  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.fb_id;
      return (
        <>
          {allowedActions.filter((data) => data.permission_id == 29)?.length >
            0 ? (
            <Link
              onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </button>
            </Link>) : null}

          {allowedActions.filter((data) => data.permission_id == 31)?.length >
            0 ? (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </button>
            </span>) : null}
        </>
      );
    },
  },
];

export const faq_columns = ({ onDeleteOpen, handleDrawer, allowedActions }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },
  {
    Header: "Title",
    accessor: (d) => capitalise(d.faq_name),
  },
  {
    Header: "Description",
    accessor: "faq_description",
  },

  {
    Header: "Position",
    accessor: "faq_is_position",
  },

  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.faq_id;
      return (
        <>
          {allowedActions.filter((data) => data.permission_id == 17)?.length >
            0 ? (
            <Link
              onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </button>
            </Link>) : null}

          {allowedActions.filter((data) => data.permission_id == 19)?.length >
            0 ? (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </button>
            </span>) : null}
        </>
      );
    },
  },
];

export const blog_columns = ({ onDeleteOpen, handleDrawer, allowedActions }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },
  {
    Header: "Title",
    accessor: (d) => capitalise(d.blog_title),
  },
  {
    Header: "Content",
    accessor: "blog_content",
  },

  {
    Header: "Slug",
    accessor: "blog_slug",
  },
  {
    Header: "Keyword",
    accessor: "blog_keyword",
  },

  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.blog_id;
      return (
        <>
          {allowedActions.filter((data) => data.permission_id == 35)?.length >
            0 ? (
            <Link
              onClick={() => handleDrawer("edit", cellId, cell.row.original)}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
            >
              <button
                className="text-cyan-900 "
                size="default"
                variant="outlined"
              >
                Edit<span className="sr-only">, </span>
              </button>
            </Link>) : null}

          {allowedActions.filter((data) => data.permission_id == 37)?.length >
            0 ? (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </button>
            </span>) : null}
        </>
      );
    },
  },
];
export const gender_columns = ({ onDeleteOpen, handleDrawer }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },
  {
    Header: "Gender English",
    accessor: (d) => capitalise(d.gender_name),
  },
  {
    Header: "Gender Arabic",
    accessor: (d) => capitalise(d.gender_name_ar),
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.gender_id;
      return (
        <>
          <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link>

          <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
            <button
              onClick={() => {
                onDeleteOpen(cellId);
              }}
              size="default"
              variant="outlined"
            >
              Delete
              <span className="sr-only">, </span>
            </button>
          </span>
        </>
      );
    },
  },
];

export const price_columns = ({ onDeleteOpen, handleDrawer, allowedActions }) => [
  {
    Header: "SN",
    accessor: (d, i) => (i + 1),
    Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
  },
  {
    Header: "Plan Name",
    accessor: (d) => capitalise(d.price_plan_name),
  },
  {
    Header: "Price",
    accessor: 'price_amt',
  },
  {
    Header: "Short Desc.",
    accessor: 'price_short_desc',
  },
  {
    Header: "Long Desc.",
    accessor: 'price_long_desc',
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (cell) => {
      const cellId = cell.row.original.price_id;
      return (
        <>
          {allowedActions.filter((data) => data.permission_id == 41)?.length >
            0 ? (
            <Link
              to={`../add-price/${cellId}`}
              className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
            >
              <button
                className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
                size="default"
                variant="outlined"
              >
                Edit
              </button>
            </Link>
          ) : null}
          {/* <Link
            onClick={() => handleDrawer("edit", cellId, cell.row.original)}
            className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
          >
            <button
              className="text-cyan-900 "
              size="default"
              variant="outlined"
            >
              Edit<span className="sr-only">, </span>
            </button>
          </Link> */}

          {allowedActions.filter((data) => data.permission_id == 43)?.length >
            0 ? (
            <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
              <button
                onClick={() => {
                  onDeleteOpen(cellId);
                }}
                size="default"
                variant="outlined"
              >
                Delete
                <span className="sr-only">, </span>
              </button>
            </span>) : null}
        </>
      );
    },
  },
];

export const customer_columns = ({
  onDeleteOpen,
  onStatusUpdate,
  allowedActions,
}) => [
    {
      Header: "Sr No.",
      accessor: (d, i) => (i + 1),
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
    },
    {
      Header: "Customer Id",
      accessor: "customer_id",
    },
    {
      Header: "Customer User Name",
      accessor: (d) => capitalise(d?.customer_username),
    },
    {
      Header: "Customer Email",
      accessor: (d) => capitalise(d?.customer_email),
    },
    {
      Header: "Customer Mobile",
      accessor: (d) => capitalise(d?.customer_mobile),
    },
    {
      Header: "Customer Gender",
      accessor: (d) => capitalise(d?.customer_gender),
    },
    {
      Header: "Customer Designation",
      accessor: (d) => capitalise(d?.customer_designation),
    },

    {
      Header: "Customer Address",
      accessor: (d) => capitalise(d?.customer_address), //capitalise(d.order_date),
    },
    {
      Header: "Status",
      accessor: (d) => (d.ord_is_active === true ? "Active" : "Inactive"),
      Cell: (cell) => {
        const cellId = cell.row.original.customer_id;
        const enabled = cell.row.original.customer_is_active;

        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 48)?.length >
              0 ? (
              <Switch
                checked={enabled}
                onChange={() => {
                  onStatusUpdate(cellId, cell.row.original.customer_is_active);
                }}
                className={productStatusToggle(
                  enabled ? "bg-[#872341]" : "bg-gray-200",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className={productStatusToggle(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            ) : null}
          </>
        );
      },
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell?.row?.original?.customer_id;
        const customer_is_active = cell?.row?.original?.customer_is_active;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 47)?.length >
              0 ? (
              <Link
                to={`../add-customer/${cellId}`}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-red-600 bg-red-100"
              >
                <button
                  className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
                  size="default"
                  variant="outlined"
                >
                  Edit
                </button>
              </Link>
            ) : null}

            {allowedActions.filter((data) => data.permission_id == 49)?.length >
              0 ? (
              <span className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId, customer_is_active);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                </button>
              </span>
            ) : null}
          </>
        );
      },
    },
  ];

  export const domain_columns = ({ onDeleteOpen, handleDrawer, allowedActions }) => [
    {
      Header: "SN",
      accessor: (d, i) => (i + 1),
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
    },
    {
      Header: "Name",
      accessor: (d) => capitalise(d.domain_name),
    },
    {
      Header: "Url",
      accessor: "domain_url",
    },
  
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.domain_id;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 59)?.length >
              0 ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit<span className="sr-only">, </span>
                </button>
              </Link>) : null}
  
            {allowedActions.filter((data) => data.permission_id == 61)?.length >
              0 ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                  <span className="sr-only">, </span>
                </button>
              </span>) : null}
          </>
        );
      },
    },
  ];


  export const apiKey_columns = ({ onDeleteOpen, handleDrawer, allowedActions }) => [
    {
      Header: "SN",
      accessor: (d, i) => (i + 1),
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
    },
    {
      Header: "Name",
      accessor: (d) => capitalise(d.ak_name),
    },
    {
      Header: "Key",
      accessor: "ak_api_key",
    },
    {
      Header: "Domain Name",
      accessor: (d) => capitalise(d?.m_domain_relation?.domain_name),
    },
    {
      Header: "Domain Url",
      accessor: (d) => capitalise(d?.m_domain_relation?.domain_url),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.ak_id;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 65)?.length >
              0 ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit<span className="sr-only">, </span>
                </button>
              </Link>) : null}
  
            {allowedActions.filter((data) => data.permission_id == 67)?.length >
              0 ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                  <span className="sr-only">, </span>
                </button>
              </span>) : null}
          </>
        );
      },
    },
  ];

  export const api_columns = ({ onDeleteOpen, handleDrawer, allowedActions }) => [
    {
      Header: "SN",
      accessor: (d, i) => (i + 1),
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
    },
    {
      Header: "Name",
      accessor: (d) => capitalise(d.api_name),
    },
    {
      Header: "Type",
      accessor: "api_type",
    },
    {
      Header: "Code",
      accessor: "api_code",
    },
    {
      Header: "Price",
      accessor: "api_price",
    },
    {
      Header: "Api Is Billing",
      accessor: (d) => d?.api_is_billing?"Yes":"No",
    },
   
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.api_id;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 71)?.length >
              0 ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit<span className="sr-only">, </span>
                </button>
              </Link>) : null}
  
            {allowedActions.filter((data) => data.permission_id == 73)?.length >
              0 ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                  <span className="sr-only">, </span>
                </button>
              </span>) : null}
          </>
        );
      },
    },
  ];

  export const subApi_columns = ({ onDeleteOpen, handleDrawer, allowedActions }) => [
    {
      Header: "SN",
      accessor: (d, i) => (i + 1),
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
    },
    {
      Header: "Name",
      accessor: (d) => capitalise(d.sapi_name),
    },
    {
      Header: "Endpointe",
      accessor: "sapi_endpointe",
    },
    {
      Header: "Code",
      accessor: "sapi_code",
    },
    {
      Header: "Uptime",
      accessor: "sapi_uptime",
    },
    {
      Header: "Api Name",
      accessor: (d) => capitalise(d?.m_master_api_relation?.api_name),
    },
    {
      Header: "Api Code",
      accessor: (d) => capitalise(d?.m_master_api_relation?.api_code),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.api_id;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 77)?.length >
              0 ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit<span className="sr-only">, </span>
                </button>
              </Link>) : null}
  
            {allowedActions.filter((data) => data.permission_id == 79)?.length >
              0 ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                  <span className="sr-only">, </span>
                </button>
              </span>) : null}
          </>
        );
      },
    },
  ];

  export const plan_columns = ({ onDeleteOpen, handleDrawer, allowedActions }) => [
    {
      Header: "SN",
      accessor: (d, i) => (i + 1),
      Cell: ({ cell }) => <div className="p-2 text-sm text-gray-500">{cell.value}</div>, // Adding a class to the cell
    },
    {
      Header: "Name",
      accessor: (d) => capitalise(d.plan_name),
    },
    {
      Header: "Currency",
      accessor: "plan_currency",
    },
    {
      Header: "Monthly Price",
      accessor: "plan_monthly_price",
    },
    {
      Header: "Yearly Price",
      accessor: "plan_yearly_price",
    },
    {
      Header: "Plan Is Tops",
      accessor: (d) => d?.plan_is_tops?"Yes":"No",
    },
    {
      Header: "Api Name",
      accessor: (d) => capitalise(d?.m_master_api_relation?.api_name),
    },
    {
      Header: "Api Code",
      accessor: (d) => capitalise(d?.m_master_api_relation?.api_code),
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (cell) => {
        const cellId = cell.row.original.plan_id;
        return (
          <>
            {allowedActions.filter((data) => data.permission_id == 83)?.length >
              0 ? (
              <Link
                onClick={() => handleDrawer("edit", cellId, cell.row.original)}
                className="inline-flex px-2 ml-3 text-xs leading-5 rounded-full text-cyan-600 bg-cyan-100"
              >
                <button
                  className="text-cyan-900 "
                  size="default"
                  variant="outlined"
                >
                  Edit<span className="sr-only">, </span>
                </button>
              </Link>) : null}
  
            {allowedActions.filter((data) => data.permission_id == 85)?.length >
              0 ? (
              <span className="inline-flex px-2 ml-3 text-xs leading-5 text-red-600 bg-red-100 rounded-full">
                <button
                  onClick={() => {
                    onDeleteOpen(cellId);
                  }}
                  size="default"
                  variant="outlined"
                >
                  Delete
                  <span className="sr-only">, </span>
                </button>
              </span>) : null}
          </>
        );
      },
    },
  ];