
import React, { useEffect, useState, Fragment } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Dialog, Menu, Transition } from '@headlessui/react'
import { useFormik } from "formik";
import { accountSchema } from "../../../schemas";
import empty from "../../../assets/images/empty.png"
import moment from "moment";
import { getAllowedActions, checkPermissionsAndRedirect, capitalizeFirstLetter } from "../../../helper/commonHelper";
import {
  ArrowDownCircleIcon,
  ArrowPathIcon,
  ArrowUpCircleIcon,
  Bars3Icon,
  EllipsisHorizontalIcon,
  PlusSmallIcon,
  ArrowDownIcon,
  ArrowUpIcon
} from '@heroicons/react/20/solid'
import {
  BellIcon, XMarkIcon,
  TvIcon,
  UsersIcon,
  UserMinusIcon,
  CircleStackIcon,
  ComputerDesktopIcon,
  ArrowTrendingUpIcon,
  BackwardIcon,
  Battery0Icon,

} from '@heroicons/react/24/outline'
import CommonServices from "../../../ApiServices/CommonServices";
import { toast } from "react-toast";
import makeAnimated from 'react-select/animated';
import Select from "react-select";
import { FaSpinner } from "react-icons/fa";
import FSLoader from "../../../components/FSLoader";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";
import Settings from "./Settings"
import ConnectedAccLoader from "../../../components/ConnectedAccLoader";
import SelectField from "../../../components/selectFieldsFilter/SelectField"

const secondaryNavigation = [
  { name: 'Last 1 Hours', value: 60, href: '#', current: false },
  { name: 'Last 1 days', value: 24, href: '#', current: false },
  { name: 'Last 30 days', value: 30, href: '#', current: false },
  { name: 'All-time', value: 0, href: '#', current: true },
]
const stats = [
  { name: 'Licenses', value: '45', change: TvIcon, changeType: 'positive' }, //positive - negative
  { name: 'Customers', value: '115', change: UsersIcon, changeType: 'positive' },
  { name: 'Logs', value: '1842', change: CircleStackIcon, changeType: 'positive' },
  { name: 'Inactive Users', value: '9', change: UserMinusIcon, changeType: 'positive' },
]

const connections = [{ value: 60, label: "Last 60 Minutes" }, { value: 24, label: "Last 24 Hours" }, { value: 30, label: "Last 30 Days" }];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function userStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Home = () => {
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);
  const [secNavigation, setSecNavigation] = useState(secondaryNavigation);
  const [dashCounts, setDashCounts] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isFSLoading, setSFLoading] = useState(false);
  const animatedComponents = makeAnimated();



  const getAllDashAccounts = async (value) => {
    setIsLoading(true);
    secNavigation.map((item) => {
      item.current = false;
      if (item.value == value) {
        item.current = true;
      }
    })
    setSecNavigation(secNavigation)
    let payload = `1`;
    try {
      const res = await CommonServices.getAllStatList(payload)
      if (res.status) {
        let stats = [];
        if (res?.data?.data) {
          let total = res?.data?.data;
          if (total?.user) {
            stats.push({ name: 'User', active: total?.user, inactive: 0, total: total?.user, change: TvIcon, changeType: 'positive' })
          }
          if (total?.customer) {
            stats.push({ name: 'Customers', active: total?.customer, inactive: 0, total: total?.customer, change: UsersIcon, changeType: 'positive' })
          }
          if (total?.blog) {
            stats.push({ name: 'Blog', active: total?.blog, inactive: 0, total: total?.blog, change: BackwardIcon, changeType: 'positive' })
          }
          if (total?.feedback) {
            stats.push({ name: 'Feedback', active: total?.feedback, inactive:0, total: total?.feedback, change: CircleStackIcon, changeType: 'positive' })
          }
          if (total?.faq) {
            stats.push({ name: 'FAQ', active: total?.faq, inactive:0, total: total?.faq, change: Battery0Icon, changeType: 'positive' })
          }
          if (total?.contact) {
            stats.push({ name: 'Contact', active: total?.contact, inactive:0, total: total?.contact, change: UserMinusIcon, changeType: 'positive' })
          }
          if (total?.plan) {
            stats.push({ name: 'Plan', active: total?.plan, inactive:0, total: total?.plan, change: UserMinusIcon, changeType: 'positive' })
          }
          if (total?.api) {
            stats.push({ name: 'Api', active: total?.api, inactive:0, total: total?.api, change: UserMinusIcon, changeType: 'positive' })
          }
          if (total?.subApi) {
            stats.push({ name: 'Sub Api', active: total?.subApi, inactive:0, total: total?.subApi, change: UserMinusIcon, changeType: 'positive' })
          }
        }
        setDashCounts(stats);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e, "error in getAllDashAccounts")
      toast.error("Fatal Error")
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect('/account', permissions, navigate);
      const actions = getAllowedActions(permissions, '/account');
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    getAllDashAccounts(0);
  }, []);


  return (
    <>
      {isLoading ? (
        <FallingLinesLoader />
      ) : (<main>

        <FSLoader isLoading={isLoading} title="Processing..." />

        <div className="relative isolate overflow-hidden bg-white border rounded-md">
          {/* Secondary navigation */}
          <header className="pb-4 pt-6 sm:pb-6">
            <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
              <h1 className="text-base font-semibold leading-7 text-gray-900">Statistics</h1>
              <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">

                <a className='text-[#0172fd]'>
                  {/* All Time */}
                </a>
              </div>

              <div className="ml-auto flex items-center gap-x-4">



              </div>
            </div>
          </header>

          {/* Stats */}
          <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
            <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
              {dashCounts?.length > 0 && dashCounts?.map((stat, statIdx) => (
                <div
                  key={stat.name}
                  className={classNames(
                    statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : '',
                    'flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8'
                  )}
                >
                  <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
                  <dd
                    className={classNames(
                      stat.changeType === 'negative' ? 'text-rose-600' : 'text-gray-700',
                      'text-xs font-medium'
                    )}
                  >
                    <stat.change
                      className="mr-3 h-10 w-10"
                      aria-hidden="true"
                    />

                  </dd>
                  <dd className="w-full flex-none text-4xl font-medium leading-10 tracking-tight text-gray-900">
                    {stat.total}
                  </dd>
                  {/* <dd className="w-full flex-none text-3xs font-medium leading-10 tracking-tight text-gray-900">
                    Active : {stat.active}
                  </dd>
                  <dd className="w-full flex-none text-3xs font-medium leading-10 tracking-tight text-gray-900">
                    In-Active : {stat.inactive}
                  </dd> */}
                </div>
              ))}
            </dl>
          </div>

          <div
            className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
            aria-hidden="true"
          >
            <div
              className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
              style={{
                clipPath:
                  'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
              }}
            />
          </div>
        </div>

        <div className="space-y-16 py-6 my-5 xl:space-y-5 border rounded-md bg-white">
          {/* Recent activity table */}
          <div>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex align-middle items-center gap-2">
                <h2 className="mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none mr-2">
                  {/* Connected Accounts */}
                </h2>

              </div>

            </div>
            <div className="mt-6 overflow-hidden border-t border-gray-100">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">



                </div>
              </div>
            </div>
          </div>

          {/* Last 60 minutes data */}
          {/* {!isConectionLoading ? ( */}

          <div className="mx-auto max-w-7xl px-4 sm:px-4 lg:px-8">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="flex items-center justify-between">
                {/* <h2 className="text-base font-semibold leading-7 text-[#0172fd]">{selectedTimeLabel}</h2> */}
              </div>



            </div>
          </div>

          {/* // ) : (

          //   <ConnectedAccLoader items={3} />

          // )} */}

          {/* Last 60 minutes > && < 24 Hours data */}
          <div className="mx-auto max-w-7xl px-4 sm:px-4 lg:px-8 d-none hidden hide">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="flex items-center justify-between">
                {/* <h2 className="text-base font-semibold leading-7 text-[#ff9747]">Last 24 Hours</h2> */}
                <a href="#" className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                  {/* View all<span className="sr-only">, customers</span> */}
                </a>
              </div>

            </div>
          </div>

          <div>

          </div>
        </div>
      </main>)}
    </>
  )
}

export default Home