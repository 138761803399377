import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../../components/Breadcrumb'
import { customerSchema } from '../../../../schemas';
import Select from 'react-select';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import CustomerServices from '../../../../ApiServices/CustomerServices';
import makeAnimated from "react-select/animated";
import { Switch } from '@headlessui/react'
import { toast } from 'react-toast';
import moment from 'moment';
import { FaSpinner } from "react-icons/fa";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AddCustomer = () => {
  const pages = [{ title: "Add Customer", href: "/add-customer", module_id: 40 }];
  const [selectedGender, setSelectedGender] = useState([])
  const [customerIsActive, setCustomerIsActive] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const { customer_id: customer_id } = useParams()
  const animatedComponents = makeAnimated();
  const navigate = useNavigate()
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);


  const gender = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    }
  ]

  useEffect(() => {
    if (customer_id) {
      getSingleCustomer(customer_id)
    }
  }, [])

  let initialValues = {
    customer_id: null,
    customer_first_name: "",
    customer_last_name: "",
    customer_username: "",
    customer_email: "",
    customer_mobile: "",
    customer_dob: "",
    customer_gender: "",
    customer_designation: "",
    customer_is_active: false,
    customer_address:"",
  }
  const [customerForm, setCustomerForm] = useState(initialValues)

  const getSingleCustomer = (customer_id) => {
    setIsLoading(true)
    CustomerServices.getSingleCustomer(customer_id).then((res) => {
      const { data, status } = res;
      if (status) {
        console.log("newCustomer", data.data)
        setSelectedGender([{
          label: data?.data?.customer_gender,
          value: data?.data?.customer_gender,
        }]);
        initialValues = {
          customer_id: parseInt(customer_id),
          customer_first_name: data?.data?.customer_first_name,
          customer_last_name: data?.data?.customer_last_name,
          customer_username: data?.data?.customer_username,
          customer_email: data?.data?.customer_email,
          customer_gender: {
            label: data?.data?.customer_gender,
            value: data?.data?.customer_gender,
          },
          customer_mobile: data?.data?.customer_mobile,
          customer_dob: moment(data?.data?.customer_dob).format("YYYY-MM-DD"),
          customer_designation: data?.data?.customer_designation,
          customer_is_active: data?.data?.customer_is_active,
          customer_address:data?.data?.customer_address,
        }
        console.log("initialValues", initialValues)
        setCustomerForm(initialValues)
      }
      setIsLoading(false)
    }).catch((error) => {
      console.log(error)
      setIsLoading(false)
    });
  }


  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: customerForm,
      validationSchema: customerSchema,
      onSubmit: async (values, action) => {
        console.log("customer_dob", values.customer_dob, errors)
        let body = {
          customer_id: customer_id ? customer_id : null,
          customer_first_name: values.customer_first_name,
          customer_last_name: values.customer_last_name,
          customer_username: values.customer_username,
          customer_email: values.customer_email,
          customer_mobile: values.customer_mobile.toString(),
          customer_dob: new Date(values.customer_dob),
          customer_gender: selectedGender.value ? selectedGender.value : values.customer_gender.value,
          customer_designation: values.customer_designation,
          customer_is_active: customerIsActive,
        };
        if (selectedGender.length <= 0) {
          toast.error("Please Select Gender");
          return false;
        }
        console.log("body", body)
        setTimeout(() => {
          setDisableSubmitButton(false)
        }, 1000)
        setDisableSubmitButton(true)
        const response = await CustomerServices.saveCustomer(body);
        if (response.data.status) {
          action.resetForm();
          setCustomerForm(initialValues);
          toast.success(response.data.message);
          setDisableSubmitButton(false)
          navigate("/customer");
        } else {
          toast.error(response.data.message);
          setDisableSubmitButton(false)
        }
      },
    });
  console.log("customer_dob", errors)
  return (
    <>
      {!isLoading ? (<div>
        <Breadcrumb pages={pages} />
        <div className="">
          <h1 className="text-xl font-semibold text-gray-900">Add Customer</h1>
        </div>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-3 mt-3 lg:w-full w-full">
              <div>
                <label htmlFor="customer_first_name" className="block text-sm font-medium leading-6 text-gray-900">
                  Customer First Name<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="customer_first_name"
                    id="customer_first_name"
                    value={values.customer_first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Customer First Name"
                  />
                  {errors.customer_first_name && touched.customer_first_name ? (
                    <div className="text-sm text-red-600">
                      {errors.customer_first_name}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="customer_last_name" className="block text-sm font-medium leading-6 text-gray-900">
                  Customer Last Name<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="customer_last_name"
                    id="customer_last_name"
                    value={values.customer_last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Customer Last Name"
                  />
                  {errors.customer_last_name && touched.customer_last_name ? (
                    <div className="text-sm text-red-600">
                      {errors.customer_last_name}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="customer_gender" className="block text-sm font-medium leading-6 text-gray-900">
                  Gender
                </label>
                <div>
                  <Select
                    name="customer_gender"
                    placeholder={
                      <span>
                        Select Gender
                        <span className="text-red-600">*</span>
                      </span>
                    }
                    onChange={setSelectedGender}
                    value={selectedGender}
                    components={animatedComponents}
                    options={gender}
                    classNamePrefix="select"
                    className="basic-multi-select block w-full max-w-lg rounded-md border-[1px]  shadow-sm  focus:border-red-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="customer_username" className="block text-sm font-medium leading-6 text-gray-900">
                  Customer Username<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="customer_username"
                    id="customer_username"
                    value={values.customer_username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Customer Username"
                  />
                  {errors.customer_username && touched.customer_username ? (
                    <div className="text-sm text-red-600">
                      {errors.customer_username}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="customer_email" className="block text-sm font-medium leading-6 text-gray-900">
                  Customer Email<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="email"
                    name="customer_email"
                    id="customer_email"
                    value={values.customer_email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Customer Email"
                  />
                  {errors.customer_email && touched.customer_email ? (
                    <div className="text-sm text-red-600">
                      {errors.customer_email}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="customer_mobile" className="block text-sm font-medium leading-6 text-gray-900">
                  Customer Mobile<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="number"
                    name="customer_mobile"
                    id="customer_mobile"
                    value={values.customer_mobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Customer Mobile"
                  />
                  {errors.customer_mobile && touched.customer_mobile ? (
                    <div className="text-sm text-red-600">
                      {errors.customer_mobile}
                    </div>
                  ) : null}
                </div>
              </div>

              <div>
                <label htmlFor="customer_designation" className="block text-sm font-medium leading-6 text-gray-900">
                  Customer Designation<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="customer_designation"
                    id="customer_designation"
                    value={values.customer_designation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Customer Designation"
                  />
                  {errors.customer_designation && touched.customer_designation ? (
                    <div className="text-sm text-red-600">
                      {errors.customer_designation}
                    </div>
                  ) : null}
                </div>
              </div>

              <div>
                <label htmlFor="customer_dob" className="block text-sm font-medium leading-6 text-gray-900">
                  Customer Dob<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="date"
                    name="customer_dob"
                    id="customer_dob"
                    value={values.customer_dob}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Start Date"
                  />
                  {errors.customer_dob && touched.customer_dob ? (
                    <div className="text-sm text-red-600">
                      {errors.customer_dob}
                    </div>
                  ) : null}
                </div>
              </div>

              <div>
                <label htmlFor="customer_address" className="block text-sm font-medium leading-6 text-gray-900">
                  Customer Address<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <textarea
                    rows="5"
                    value={values.customer_address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    placeholder="Enter Address"
                    name="customer_address"
                    autoComplete="off"
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.customer_address &&
                    touched.customer_address ? (
                    <p className="text-red-600 text-sm">
                      {errors.customer_address}
                    </p>
                  ) : null}
                </div>
              </div>

              <div>
                <label htmlFor="customerIsActive" className="block text-sm font-medium leading-6 text-gray-900">
                  Customer Is Active
                </label>
                <Switch
                  checked={customerIsActive}
                  onChange={setCustomerIsActive}
                  className={classNames(
                    customerIsActive ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      customerIsActive ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                disabled={disableSubmitButton}
                type="submit"
                className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
              >
                {customer_id ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Save")}
              </button>
            </div>
          </form>
        </div>

      </div>) : (

        <FallingLinesLoader />
      )}</>
  )
}

export default AddCustomer