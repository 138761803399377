/* eslint-disable */
import Api from "./Api";
export default {

  async SaveFeedback(payload) {
    const response = await Api().post(`api/dashboard/feedback`,payload);
    return response;
  },

  async getAllFeedback(payload) {
    const response = await Api().get(`api/dashboard/feedback?search=${payload}`);
    return response;
  },
  
  async getSingleFeedback(payload) {
    const response = await Api().get(`api/dashboard/feedback/${payload}`);
    return response;
  },

  async setFeedbackStatus(payload) {
    const response = await Api().post(`api/dashboard/feedback/status`, payload);
    return response;
  },

  async deleteFeedback(payload) {
    const response = await Api().post(`api/dashboard/feedback/remove`, payload);
    return response;
  },

};
