/* eslint-disable */
import Api from "./Api";
export default {

  async SavePriceing(payload) {
    const response = await Api().post(`api/dashboard/price`,payload);
    return response;
  },

  async getAllPriceing(payload) {
    const response = await Api().get(`api/dashboard/price?search=${payload}`);
    return response;
  },
  
  async getSinglePriceing(payload) {
    const response = await Api().get(`api/dashboard/price/${payload}`);
    return response;
  },

  async setPriceingStatus(payload) {
    const response = await Api().post(`api/dashboard/price/status`, payload);
    return response;
  },

  async deletePriceing(payload) {
    const response = await Api().post(`api/dashboard/price/remove`, payload);
    return response;
  },

};
