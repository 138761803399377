import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../../components/Breadcrumb'
import { priceSchema } from '../../../../schemas';
import Select from 'react-select';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import PriceingServices from '../../../../ApiServices/PriceingServices';
import makeAnimated from "react-select/animated";
import { Switch } from '@headlessui/react'
import { toast } from 'react-toast';
import moment from 'moment';
import { FaSpinner } from "react-icons/fa";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AddPrice = () => {
  const pages = [{ title: "Add Price", href: "/add-price", module_id: 40 }];
  const [selectedGender, setSelectedGender] = useState([])
  const [price_is_active, setPriceIsActive] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const { price_id: price_id } = useParams()
  const animatedComponents = makeAnimated();
  const [keyword, setKeyword] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);
  const navigate = useNavigate()
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);


  useEffect(() => {
    if (price_id) {
      getSinglePrice(price_id)
    } else {
      setKeyword([
        {
          id: 1,
          keyword: "",
        },
      ]);
    }
  }, [])

  const addKeyword = () => {
    const newKeyword = {
      id: keyword.length + 1,
      keyword: "",
    };

    setKeyword([...keyword, newKeyword]);
  };
  const removeKeyword = (keywordId) => {
    setKeyword((currentkeywords) =>
      currentkeywords.filter(
        (item) => item.id !== keywordId
      )
    );
  };
  const handleKeywordChange = (id, value) => {
    const updatedKeywords = keyword.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          keyword: value,
        };
      }
      return item;
    });
    setKeyword(updatedKeywords);
    validateField("keyword", value ? value : null, id);
  };

  const validateField = (fieldName, value, keywordId = null) => {
    let errors = { ...formErrors };

    switch (fieldName) {
      case "keyword":
        if (value) {
          delete errors[`keyword_${keywordId}_keyword`];
        } else {
          errors[`keyword_${keywordId}_keyword`] = `Please Enter Keyword ${keywordId} Fields is Required.`;
        }
        break;
    }

    setFormErrors(errors);
  };

  const validateForm = () => {
    let validationErrors = {};

    keyword.forEach((item) => {
      if (!item.keyword) {
        console.log("item.keyword=>", item.keyword)
        validationErrors[`keyword_${item.id}_keyword`] = `Please Enter Keyword ${item.id} Fields is Required.`;
      }

    });

    return validationErrors;
  };

  let initialValues = {
    price_id: "",
    price_plan_name: "",
    price_short_desc: "",
    price_long_desc: "",
    price_amt: "",
    price_is_active: "",
  }
  const [priceForm, setPriceForm] = useState(initialValues)

  const getSinglePrice = (price_id) => {
    setIsLoading(true)
    setKeyword([]);
    PriceingServices.getSinglePriceing(price_id).then((res) => {
      const { data, status } = res;
      if (status) {

        initialValues = {
          price_id: parseInt(price_id),
          price_plan_name: data?.data?.price_plan_name,
          price_amt: data?.data?.price_amt,
          price_short_desc: data?.data?.price_short_desc,
          price_long_desc: data?.data?.price_long_desc,
          price_is_active: data?.data?.price_is_active,
        }
        setKeyword(data?.data?.price_features);
        console.log("initialValues", initialValues)
        setPriceForm(initialValues)
      }
      setIsLoading(false)
    }).catch((error) => {
      console.log(error)
      setIsLoading(false)
    });
  }


  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: priceForm,
      validationSchema: priceSchema,
      onSubmit: async (values, action) => {
        let body = {
          price_id: price_id ? price_id : null,
          price_plan_name: values.price_plan_name,
          price_amt: values.price_amt,
          price_short_desc: values.price_short_desc,
          price_long_desc: values.price_long_desc,
          price_is_active: price_is_active,
          price_features: keyword,
        };
        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
          setFormErrors(validationErrors);
          setIsSubmitAttempted(true);
          console.error("Validation errors:", validationErrors);
          return;
        }

        setFormErrors({});
        setIsSubmitAttempted(false);
        console.log("body", body)
        setTimeout(() => {
          setDisableSubmitButton(false)
        }, 1000)
        setDisableSubmitButton(true)
        const response = await PriceingServices.SavePriceing(body);
        if (response.data.status) {
          action.resetForm();
          setPriceForm(initialValues);
          toast.success(response.data.message);
          setDisableSubmitButton(false)
          navigate("/price");
        } else {
          toast.error(response.data.message);
          setDisableSubmitButton(false)
        }
      },
    });
  console.log("errors", errors)
  return (
    <>
      {!isLoading ? (<div>
        <Breadcrumb pages={pages} />
        <div className="">
          <h1 className="text-xl font-semibold text-gray-900">Add Price</h1>
        </div>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-6 gap-6 mt-6 lg:w-full w-full">
              <div>
                <label htmlFor="price_plan_name" className="block text-sm font-medium leading-6 text-gray-900">
                  Price Plan Name<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="price_plan_name"
                    id="price_plan_name"
                    value={values.price_plan_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Price Plan Name"
                  />
                  {errors.price_plan_name && touched.price_plan_name ? (
                    <div className="text-sm text-red-600">
                      {errors.price_plan_name}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="price_amt" className="block text-sm font-medium leading-6 text-gray-900">
                  Price AMt<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="number"
                    name="price_amt"
                    id="price_amt"
                    value={values.price_amt}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Price AMT"
                  />
                  {errors.price_amt && touched.price_amt ? (
                    <div className="text-sm text-red-600">
                      {errors.price_amt}
                    </div>
                  ) : null}
                </div>
              </div>

              <div>
                <label htmlFor="price_short_desc" className="block text-sm font-medium leading-6 text-gray-900">
                  Price Short Desc.<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <textarea
                    rows="2"
                    value={values.price_short_desc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    placeholder="Enter Short Desc"
                    name="price_short_desc"
                    autoComplete="off"
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.price_short_desc &&
                    touched.price_short_desc ? (
                    <p className="text-red-600 text-sm">
                      {errors.price_short_desc}
                    </p>
                  ) : null}
                </div>
              </div>

              <div>
                <label htmlFor="price_long_desc" className="block text-sm font-medium leading-6 text-gray-900">
                  Price Long Desc.<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <textarea
                    rows="2"
                    value={values.price_long_desc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    placeholder="Enter Long Desc"
                    name="price_long_desc"
                    autoComplete="off"
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.price_long_desc &&
                    touched.price_long_desc ? (
                    <p className="text-red-600 text-sm">
                      {errors.price_long_desc}
                    </p>
                  ) : null}
                </div>
              </div>


              <div>
                <label htmlFor="price_is_active" className="block text-sm font-medium leading-6 text-gray-900">
                  Price Is Active
                </label>
                <Switch
                  checked={price_is_active}
                  onChange={setPriceIsActive}
                  className={classNames(
                    price_is_active ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      price_is_active ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </div>
              <div>
                <button
                  onClick={addKeyword}
                  type="button"
                  className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
                >
                  Add
                </button>
              </div>
            </div>

            {/* <div className="grid grid-cols-12 md:grid-cols-12 sm:grid-cols-12 lg:grid-cols-12 gap-12 mt-12 lg:w-full w-full"> */}
            <div className="shadow overflow-hidden  border border-gray-200 lg:rounded-lg">

              <table className=" table-auto w-full">
                <thead className="bg-gray-50">
                  <tr className=" ">
                    <th
                      scope="col"
                      className="   group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      SN
                    </th>
                    <th
                      scope="col"
                      className="  group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Keyword
                    </th>
                    <th
                      scope="col"
                      className="group px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {keyword.map((item) => (
                    <tr key={item.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {item.id}
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <input
                          value={item.keyword}
                          isDisabled={item?.id <= 0 ? true : false}
                          onChange={(e) => { handleKeywordChange(item?.id, e.target.value) }}
                          type="text"
                          placeholder="Enter Keyword"
                          name={`keyword_${item.id}_keyword`}
                          autoComplete="off"
                          className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                        />

                        {formErrors[
                          `keyword_${item.id}_keyword`
                        ] && (
                            <span className="text-red-600">
                              {
                                formErrors[
                                `keyword_${item.id}_keyword`
                                ]
                              }
                            </span>
                          )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <button
                          onClick={() => removeKeyword(item.id)}
                          className="text-red-500 hover:text-red-700"
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* </div> */}
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                disabled={disableSubmitButton}
                type="submit"
                className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
              >
                {price_id ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Save")}
              </button>
            </div>
          </form>
        </div>

      </div>) : (

        <FallingLinesLoader />
      )}</>
  )
}

export default AddPrice