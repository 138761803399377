import React, { useEffect, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { faqSchema } from "../../../../schemas";
import Table from "../../../../components/tables/table";
import { faq_columns } from "../../../../components/tables/tableheader";
import { Dialog, Transition } from "@headlessui/react";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import Breadcrumb from "../../../../components/Breadcrumb";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toast";
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../../helper/commonHelper";
import { FaSpinner } from "react-icons/fa";
import FaqServices from "../../../../ApiServices/FaqServices"

function FaqList() {
	const pages = [{ title: "Faq List", href: "/faq", module_id: 3 }];
	const [isLoading, setIsLoading] = useState(true);
	const [modalOpenFlage, setmodalOpenFlage] = useState(false);
	const [faqList, setFaqList] = useState([]);
	const [faq_id, setFaqid] = useState(0);
	const [confirmationModal, setConfirmationModal] = useState({
		status: false,
		faq_id: null,
	});
	const navigate = useNavigate();
	const [disableSubmitButton, setDisableSubmitButton] = useState(false);

	const [allowedActions, setAllowedActions] = useState([]);
	useEffect(() => {
		const permissions = JSON.parse(localStorage.getItem("user_permissions"));
		if (permissions && window.location.pathname) {
			const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
			const actions = getAllowedActions(permissions, window.location.pathname);
			setAllowedActions(actions);
		}
	}, []);

	useEffect(() => {
		document.title = "Fire  • Faqs";
		getAllFaqs(3);
	}, []);
	const getAllFaqs = async (payload) => {
		setIsLoading(true);
		try {
			const res = await FaqServices.getAllFaq(payload)
			if (res.status) {
				setFaqList(res.data.data);
			}
			setIsLoading(false);
		} catch (e) {
			console.log(e, "error in getAllFaqs")
			toast.error("Fatal Error")
		}
	}

	const initialValues = {
		faq_id: "",
		faq_name: "",
		faq_description: "",
		faq_is_position: "",
		faq_is_active: "",
	};

	const [formFaq, setFormFaq] = useState(initialValues);

	const handleDrawer = (type, id, obj) => {
		if ((type === "edit", id)) {
			setFaqid(id);
			const initialValues = {
				faq_id: obj.faq_id,
				faq_name: obj.faq_name,
				faq_description: obj.faq_description,
				faq_is_position: obj.faq_is_position,
				faq_is_active: obj.faq_is_active,
			};
			setFormFaq(initialValues);
		} else {
			setFaqid("");
			setFormFaq(initialValues);
		}
		if (modalOpenFlage === false) {
			setmodalOpenFlage(true);
		}
	};
	const onDeleteOpen = (faq_id) => {
		setConfirmationModal({ faq_id, status: true });
	};

	const onDeleteFaq = async (faq_id) => {
		setIsLoading(true);
		let { data, status } = await FaqServices.deleteFaq({ faq_id })
		if (status === 200) {
			setIsLoading(false);
			if (data.status) {
				toast.success(data.message);
				getAllFaqs(3);
			}
		} else {
			toast.error(data.message);
		}
		setIsLoading(false);
		setConfirmationModal({ faq_id: null, status: false });
	};


	const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
		useFormik({
			enableReinitialize: true,
			initialValues: formFaq,
			validationSchema: faqSchema,
			onSubmit: async (values, action) => {
				console.log("Submit Clicked", values);
				let body = {
					faq_id: faq_id,
					faq_is_active: true,
					faq_name: values.faq_name,
					faq_description: values.faq_description,
					faq_is_position: values.faq_is_position,
				};
				if (faq_id === undefined || faq_id === null || faq_id === "") {
					setIsLoading(true);
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					await upsertFaq(body);
				} else {
					setIsLoading(true);
					setTimeout(() => {
						setDisableSubmitButton(false)
					}, 1000)
					setDisableSubmitButton(true)
					await upsertFaq(body);
				}
				action.resetForm();
				getAllFaqs(3);
				if (modalOpenFlage === true) {
					getAllFaqs(3);
					setmodalOpenFlage(false);
					setDisableSubmitButton(false);
				}
				navigate("/faq");
			},
		});
	const upsertFaq = async (payload) => {
		let { data, status } = await FaqServices.SaveFaq(payload)
		if (status === 200) {
			if (data.status) {
				toast.success(data.message);
			}
		} else {
			toast.error(data.message);
		}
		setIsLoading(false);
	}
	return (
		<div className="px-4 sm:px-4 lg:px-4">
			<ConfirmationModal
				title={'Are you sure you want to delete this faq?'}
				open={confirmationModal.status}
				setOpen={setConfirmationModal}
				onDelete={() => onDeleteFaq(confirmationModal.faq_id)}
			/>
			<Breadcrumb pages={pages} />
			<div className="">
				<h1 className="text-xl font-semibold text-gray-900">Faq List</h1>
			</div>
			<div className="mt-4 flex">
				{allowedActions.filter((data) => data.permission_id == 16)?.length > 0 ? (
				<Link
					onClick={() => handleDrawer("add", "", {})}
					type="button"
					className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
				>
					Add Faq
				</Link>
				) : null}
			</div>

			{isLoading ? (
				<FallingLinesLoader />
			) : (
				<Table
					columns={faq_columns({ onDeleteOpen, handleDrawer, allowedActions })}
					data={faqList}
					is_toggle={false}
				/>
			)}

			<div>
				<Transition.Root show={modalOpenFlage} as={Fragment}>
					<Dialog
						as="div"
						className="relative z-10"
						onClose={setmodalOpenFlage}
					>
						<div className="fixed inset-0" />
						<div className="fixed inset-0 overflow-hidden">
							<div className="absolute inset-0 overflow-hidden">
								<div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
									<Transition.Child
										as={Fragment}
										enter="transform transition ease-in-out duration-500 sm:duration-700"
										enterFrom="translate-x-full"
										enterTo="translate-x-0"
										leave="transform transition ease-in-out duration-500 sm:duration-700"
										leaveFrom="translate-x-0"
										leaveTo="translate-x-full"
									>
										<Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
											<form
												onSubmit={(e) => {
													e.preventDefault();
													console.log(e);
													handleSubmit(e);
												}}
												className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
											>
												<div className="h-0 flex-1 overflow-y-auto">
													<div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9%    to-gray-900 to-80%  py-6 px-4 sm:px-6">
														<div className="flex items-center justify-between">
															<Dialog.Title className="text-lg font-medium text-white">
																{faq_id>0 ? "Update" : "Add"} Faq
															</Dialog.Title>
															<div className="ml-3 flex h-7 items-center">
																<button
																	type="button"
																	className="rounded-md bg-gradient-to-b from-[#11ffdb] to-[#0172fd] text-white hover:text-white"
																	onClick={() => setmodalOpenFlage(false)}
																>
																	<span className="sr-only">Close panel</span>
																	<XMarkIcon
																		className="h-6 w-6"
																		aria-hidden="true"
																	/>
																</button>
															</div>
														</div>
													</div>
													<div className="flex flex-1 flex-col justify-between">
														<div className="divide-y divide-gray-200 p-4 sm:p-6">
															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="faq_name"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Faq Title
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.faq_name}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Faq Title"
																		name="faq_name"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.faq_name &&
																		touched.faq_name ? (
																		<p className="text-red-600 text-sm">
																			{errors.faq_name}
																		</p>
																	) : null}
																</div>
															</div>

															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="faq_description"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Faq Description
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<textarea
																		rows="5"
																		value={values.faq_description}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="text"
																		placeholder="Enter Faq Description"
																		name="faq_description"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.faq_description &&
																		touched.faq_description ? (
																		<p className="text-red-600 text-sm">
																			{errors.faq_description}
																		</p>
																	) : null}
																</div>
															</div>

															<div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
																<div>
																	<label
																		htmlFor="faq_is_position"
																		className="block text-sm font-medium text-gray-900 mt-2 mb-1"
																	>
																		Enter Faq Position
																	</label>
																</div>
																<div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
																	<input
																		value={values.faq_is_position}
																		onChange={handleChange}
																		onBlur={handleBlur}
																		type="number"
																		placeholder="Enter Faq Position"
																		name="faq_is_position"
																		autoComplete="off"
																		className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
																	/>
																	{errors.faq_is_position &&
																		touched.faq_is_position ? (
																		<p className="text-red-600 text-sm">
																			{errors.faq_is_position}
																		</p>
																	) : null}
																</div>
															</div>

														</div>
													</div>
												</div>
												<div className="flex flex-shrink-0 justify-end px-4 py-4">
													<button

														type="button"
														className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
														onClick={() => {
															setmodalOpenFlage(false);
														}}
													>
														Cancel
													</button>
													<button
														disabled={disableSubmitButton}
														type="submit"
														className="ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b from-[#11ffdb] to-[#0172fd] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
													>
														{faq_id>0 ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Add")}

													</button>
												</div>
											</form>
										</Dialog.Panel>
									</Transition.Child>
								</div>
							</div>
						</div>
					</Dialog>
				</Transition.Root>
			</div>
		</div>
	);
}
export default FaqList;
